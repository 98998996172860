export const DASHBOARD_ENDPOINT = {
  group_users_by_gender: `${import.meta.env.VITE_APP_API}/users/group-users-by-gender`,
  group_users_by_status: `${import.meta.env.VITE_APP_API}/users/group-users-by-status`,
  number_of_new_users_per_month: `${import.meta.env.VITE_APP_API}/users/count-users-by-created-month`,
  export_users: `${import.meta.env.VITE_APP_API}/users/export-users`,
  get_timelines: `${import.meta.env.VITE_APP_API}/timelines/get-recommended-timelines`,
  timelines: `${import.meta.env.VITE_APP_API}/timelines/get-timelines`,
  group_timelines_by_status: `${import.meta.env.VITE_APP_API}/timelines/group-timelines-by-status`,
  group_timelines_by_category: `${import.meta.env.VITE_APP_API}/timelines/group-timelines-by-category`,
  number_of_new_timelines_per_month: `${import.meta.env.VITE_APP_API}/timelines/count-timelines-by-created-month`,
};
