import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Center, Group, PasswordInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useResetPasswordMutation } from "../store/account.query";

export default function UpdatePasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const schema = yup
    .object()
    .shape({
      password: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .required("New Password is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .test(
          "",
          "Confirm Password must be the same with the New Password",
          (value) => {
            if (getValues("password") === value) {
              return true;
            } else {
              return false;
            }
          }
        )
        .required("Confirm Password is required"),
    })
    .required();

  const defaultValue = {
    password: "",
    confirmPassword: "",
  };
  const [resetPassword, resetPasswordResponse] = useResetPasswordMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });

  const onSubmit = (data: any) => {
    const resetData = {
      password: data.password,
      confirmPassword: data.confirmPassword,
      token: searchParams.get("token"),
      id: searchParams.get("id"),
    };
    resetPassword(resetData).then((response: any) => {
      if (response.data === true) {
        navigate("/login");
      }
    });
  };
  const onError = (error: any) => {
    
  };

  return (
    <>
      <div className="bg-sky-100 h-screen w-full flex justify-center items-center">
        <Center className="w-2/4 relative z-20  h-1/2 border py-4 bg-white">
          
          <div className="flex-col space-y-4 w-full">
            <div className="w-full flex justify-center">
              {!searchParams.get("token") && (
                <img src="/success.png" alt="img" className=" w-24" />
              )}
            </div>
            {searchParams.get("token") ? (
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full flex justify-center "
              >
                <div className="w-2/4 flex-col space-y-4">
                  <PasswordInput
                    placeholder="New Password"
                    label="New Password"
                    error={errors?.password && `${errors?.password?.message}`}
                    {...register("password")}
                    withAsterisk
                  />
                  <PasswordInput
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    description="Password must be the same with the new password"
                    withAsterisk
                    error={
                      errors?.confirmPassword &&
                      `${errors?.confirmPassword?.message}`
                    }
                    {...register("confirmPassword")}
                  />

                  <div className="w-full flex justify-center items-center">
                    <Group position="center" mt="xl">
                      <Button
                        variant="filled"
                        className="bg-primary w-40 text-white"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        loading={resetPasswordResponse?.isLoading}
                      >
                        <span>Done</span>
                      </Button>
                    </Group>
                  </div>
                </div>
              </form>
            ) : (
              <div className="flex justify-center text-sm">
                Reset link sent to your email.
              </div>
            )}
          </div>
        </Center>
      </div>
    </>
  );
}
