import { HoverCard, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import { User } from "../../../models/user.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetUsersQuery } from "../store/user.query";

export default function UserPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getUsers, users] = useLazyGetUsersQuery();

  useEffect(() => {
    getUsers(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/user/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: User[] | undefined = users.data?.data;

  const config: EntityConfig<User> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/user-management/user",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "name", name: "Name",render: (user) => {
        return (
          <Text size="sm" className={user?.deletedAt ? "text-danger" : ""}>
            {user.name}
          </Text>
        );
      } },
      { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone" },
      {
        key: "gender",
        name: "Gender",
        render: (value) => {
          return value.gender.toLowerCase() === "male" ? "Male" : "Female";
        },
      },
      {
        key: "archiveReason",
        name: "Archive Reason",
        hideSort: true,
        render: (user: User) => {
          
          if(user.archiveReason){
            return (
              <HoverCard width={280} shadow="md">
                <HoverCard.Target>
                  <Text size="sm" className={"cursor-pointer"}>
                    {(user?.archiveReason && user?.archiveReason?.length > 25)
                      ? user?.archiveReason?.substring(0, 22) + "..."
                      : user?.archiveReason}
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  className={"text-justify break-all wrap max-h-60 overflow-auto"}
                >
                  <Text size="sm">{user.archiveReason}</Text>
                 
                </HoverCard.Dropdown>
              </HoverCard>
            )
          }else{
            return "Not archived";
          }
          
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      {
        key: "enabled",
        name: "Status",
      },
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Users"}
        total={users?.data?.count}
        itemsLoading={users.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) => {
          if(collection?.filter||data.length>0){
            setCollection({ ...collection, filter: data });
           }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
