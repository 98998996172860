import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./store/app.store";
import App from "./App"
import "./index.css"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>

  {/* <ErrorBoundary> */}
  <Provider store={store}>
    <App />
  </Provider>
  {/* </ErrorBoundary> */}
</React.StrictMode>
)
