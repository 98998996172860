import { Tooltip } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../shared/store/slice/theme-slice";
import { RootState } from "../../store/app.store";

interface Props {
  onCollapse: any;
}
export default function Header(props: Props) {
  const dispatch = useDispatch();
  const themeClass = useSelector(
    (state: RootState) => state.themeReducer.class
  );
  const { onCollapse } = props;
  return (
    <div className="h-14 border-0 border-b  duration-500 bg-gray-900 border-gray-500 text-white items-center w-full flex justify-between px-4">
      <div className="flex space-x-6 items-center h-full">
        <div className="cursor-pointer flex space-x-4" onClick={() => onCollapse()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 fill-current"
            viewBox="0 0 26 26"
          >
            <path d="M0 4L0 6L26 6L26 4 Z M 0 12L0 14L26 14L26 12 Z M 0 20L0 22L26 22L26 20Z" />
          </svg>
        </div>
       
      </div>
      <div className="flex space-x-6 items-center">
      
        {themeClass === "light" ? (
          <div
            onClick={() => {
              dispatch(setTheme("dark"));
              localStorage.setItem("theme", "dark");
            }}
            className="border p-1 rounded"
          >
            <Tooltip className="text-sm" label="Dark mode" withArrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <desc>
                  Download more icon variants from
                  https://tabler-icons.io/i/moon
                </desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
              </svg>
            </Tooltip>
          </div>
        ) : (
          <div
            onClick={() => {
              dispatch(setTheme("light"));
              localStorage.setItem("theme", "light");
            }}
            className="dark:border-white rounded border p-1"
          >
            <Tooltip className="text-sm" label="Light mode" withArrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
              </svg>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}
