import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import EmployeePage from "../page/employee-page";
import NewEmployeeComponent from "../component/new-employee-component";
export const EmployeeRoute: RouteObject = {
  path: "/employee-management/employee",
  element: (
    <AuthGuard role={["super_admin"]}>
      <EmployeePage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewEmployeeComponent editMode="detail" /> },
    { path: ":id", element: <NewEmployeeComponent editMode="new" /> },
  ],
};
