export const ACTIVITY_LOG_ENDPOINT={
    detail:`${import.meta.env.VITE_APP_API}/activities/get-activity`,
    delete:`${import.meta.env.VITE_APP_API}/activities/delete-activity`,
    toggleStatus:`${import.meta.env.VITE_APP_API}/activities/activate-or-block-activity`,
    archive:`${import.meta.env.VITE_APP_API}/activities/archive-activity`,
    restore:`${import.meta.env.VITE_APP_API}/activities/restore-activity`,
    archivedList:`${import.meta.env.VITE_APP_API}/activities/get-archived-activities`,
    archivedActivity:`${import.meta.env.VITE_APP_API}/activities/get-archived-activity`,
    list:`${import.meta.env.VITE_APP_API}/activities/get-activities`,
    create:`${import.meta.env.VITE_APP_API}/activities/create-activity`,
    update:`${import.meta.env.VITE_APP_API}/activities/update-activity`,
    getAccounts: `${import.meta.env.VITE_APP_API}/accounts/get-accounts`,
}