import "./App.css"
import { NavigationProgress } from "@mantine/nprogress"
import { Suspense, useEffect, useState } from "react"
import { AuthContextProvider } from "./shared/auth/context/authContext"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Center, LoadingOverlay, MantineProvider } from "@mantine/core"
import AuthGuard from "./shared/auth/component/auth"
import Dashboard from "./layout/page/dashboard.page"
import NotFound from "./shared/component/404-not-found/404-not-found"
import Login from "./shared/auth/component/login/login-component"
import { RootState } from "./store/app.store"
import { useSelector } from "react-redux"
import { EmployeeRoute } from "./feature/employee/route/employee.route"
import { UserRoute } from "./feature/user/route/user.route"
import { RoleRoute } from "./feature/role/route/role-route"
import { ConfigurationRoute } from "./feature/configuration/route/configuration.route"
import { TimelineRoute } from "./feature/timeline/route/timeline.route"
import { CategoryRoute } from "./feature/category/route/category.route"
import {
  TimelineDashboardRoute,
  UserDashboardRoute,
} from "./feature/dashboard/route/dashboard.route"
import { ActivityRoute } from "./feature/activity/route/activity.route"
import {
  AccountRoute,
  ChangePasswordRoute,
  UpdatePasswordRoute,
} from "./feature/account/route/account.route"

function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(false)
  }, [])
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthContextProvider>
          <Suspense fallback={<LoadingOverlay visible={true} />}>
            <AuthGuard>
              <Dashboard />
            </AuthGuard>
          </Suspense>
        </AuthContextProvider>
      ),
      children: [
        EmployeeRoute,
        UserRoute,
        RoleRoute,
        ConfigurationRoute,
        TimelineRoute,
        CategoryRoute,
        // DashboardRoute,
        UserDashboardRoute,
        TimelineDashboardRoute,
        ActivityRoute,
        ChangePasswordRoute,
        UpdatePasswordRoute,
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "/login",
      element: (
        <AuthContextProvider>
          <Login />
        </AuthContextProvider>
      ),
    },
    AccountRoute,
    { path: "*", element: <NotFound /> },
  ])

  const themeClass = useSelector((state: RootState) => state.themeReducer.class)
  return (
    <>
      {loading ? (
        <Center>
          <LoadingOverlay visible={true} />
        </Center>
      ) : (
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme: themeClass === "dark" ? "dark" : "light",
            colors: {
              dark: [
                "#d5d7e0",
                "#acaebf",
                "#8c8fa3",
                "#666980",
                "#4d4f66",
                "#34354a",
                "#2b2c3d",
                "#1d1e30",
                "#0c0d21",
                "#01010a",
              ],
              //    Optional Dark Mode: #374151 : #1d1e30,
            },
          }}
        >
          <NavigationProgress />

          <div className={themeClass}>
            <RouterProvider router={router} />
          </div>
        </MantineProvider>
      )}
    </>
  )
}

export default App
