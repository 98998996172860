import { Anchor, Button, HoverCard, LoadingOverlay, Table, Text } from "@mantine/core"
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconFileExport,
} from "@tabler/icons-react"
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"

import { lazy, useEffect, useState } from "react"
import { Bar, Doughnut } from "react-chartjs-2"
import { decodeMonth } from "../../../shared/constants/Constants"
import { dateFormat } from "../../../shared/utility/date-format/date-format"
import { StatsRing } from "../component/status-ring"
import {
  useLazyGetTrendingTimelinesQuery,
  useLazyGroupTimelinesByCategoryQuery,
  useLazyGroupTimelinesByStatusQuery,
  useLazyNumberOfNewTimelinesPerMonthQuery,
} from "../store/dashboard.query"

import { Timeline } from "../../../models/timeline.model"
import { useLazyGetTimelinesQuery } from "../../timeline/store/timeline.query"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
)

interface StatsRingProps {
  data: {
    label: string
    stats: string
    progress: number
    color: string
    icon: "up" | "down"
  }[]
}
const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
}

export const options = {
  responsive: true,
  // plugins: {
  //   legend: {
  //     position: "top" as const,
  //   },
  //   title: {
  //     display: true,
  //     text: "Timeline Status",
  //   },
  // },
}

export const donut_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    labels: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Timeline Status by Category",
    },
  },
}

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Timelines",
    },
  },
}

const labels = ["January", "February", "March", "April", "May", "June", "July"]
const active = [100, 500, 550, 635, 500, 600, 900]
const deactive = [10, 3, 200, 100, 4, 14, 62]

export const data = {
  labels,
  datasets: [
    {
      label: "Active",
      data: labels.map((_, index) => active[index]),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Inactive",
      data: labels.map((_, deactiveIndex) => deactive[deactiveIndex]),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
}

export default function TimelineDashboardPage() {
  const [donut_state_data, setDonutData] = useState<any>()
  const [bar_state_data, setBarData] = useState<any>()
  const [passengersCircleProgress, setOwnersCircleProgress] = useState<any[]>(
    [],
  )
  const [getTimelines, timelines] = useLazyGetTrendingTimelinesQuery()
  const [getTotalTimelines, totalTimelines] = useLazyGetTimelinesQuery()
  const [groupTimelinesByCategory, categoryGroupedTimelines] =
    useLazyGroupTimelinesByCategoryQuery()
  const [groupTimelinesByStatus, groupedTimelines] =
    useLazyGroupTimelinesByStatusQuery()
  const [getNewTimelinesCountEveryMonth, newTimelinesPerMonth] =
    useLazyNumberOfNewTimelinesPerMonthQuery()

  useEffect(() => {
    getTotalTimelines({
      skip: 0,
      top: 10000000000,
      count: true,
      withArchived: true,
    })
  }, [])
  useEffect(() => {
    getTimelines().then((userResponse) => {
      groupTimelinesByStatus({
        groupBy: ["enabled"],
      }).then((response) => {
        if (response?.data && userResponse?.data) {
          let total = userResponse?.data?.count ?? 1

          const data = response.data.map((item: any) => {
            return {
              label: item.status ? "Active" : "Inactive",
              stats: item.count,
              progress: (item.count / total) * 100,
              color: item.status ? "green" : "red",
              icon: item.status ? "up" : "down",
            }
          })
          setOwnersCircleProgress(data)
        }
      })
    })

    let labels: string[]
    let data: number[]
    async function getNewTimelinesEveryMonth() {
      const response = await getNewTimelinesCountEveryMonth()
      if (response?.data) {
        labels = response.data.map((item: any) => decodeMonth(item.createdAt))
        data = response.data.map((item: any) => item.count)
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Timelines",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      }

      setBarData(bar_data)
    }
    getNewTimelinesEveryMonth()
  }, [])

  useEffect(() => {
    let labels: any[] = []
    let data: any[] = []

    async function getVehiclesByCategory() {
      const response = await groupTimelinesByCategory({ groupBy: ["category"] })
      if (response.data) {
        labels = response.data.map((item: any) => {
          return item.category
        })
        data = response.data.map((item: any) => {
          return item.count
        })
      }

      const donut_data = {
        labels: labels,
        datasets: [
          {
            label: "Timeline",
            data: data ?? [0],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      }
      setDonutData(donut_data)
    }
    getVehiclesByCategory()
  }, [])

  return (
    <div className="w-full p-4">
      <div className="flex w-full h-16 px-2 justify-between items-center mt-2 border border-sky-300 mb-2">
        <div className="flex-col space-y-2">
          <div className="capitalize font-semibold text-primary">
            Timeline dashboard report
          </div>
          <div className="capitalize font-semibold text-primary">
            {"Total: "}
            {totalTimelines?.data?.count}
          </div>
        </div>

        {/* <div className="flex space-x-4">
          <Button
            leftIcon={<IconFileExport color="gray" />}
            size="sm"
            variant="default"
            onClick={()=>setOpenExport(true)}
          >
            Export
          </Button>
        </div> */}
      </div>
      <div className="flex space-x-2 justify-between">
        <div className=" w-full shadow border rounded shadow-sky-100 p-2">
          <div className="mb-2 flex justify-between px-2">
            <Text className="text-sm text-gray-600 text-semibold">
              Timeline status
            </Text>
            <Text className="text-sm text-primary text-semibold">
              {"Total: "}
              {totalTimelines?.data?.count}
            </Text>
          </div>
          {groupedTimelines?.data && (
            <StatsRing data={passengersCircleProgress} />
          )}
          <div className=" w-full border rounded p-2 mt-2">
            <Table verticalSpacing="xs">
              <thead>
                <tr className="text-sm leading-5">
                  <td>Posted By</td>
                  <td>Views</td>
                  <td>Likes</td>
                  <td>Comments</td>
                  <td>Shares</td>
                  <td>Favorites</td>
                  <td>Posted At</td>
                </tr>
              </thead>
              <tbody>
                {timelines.isLoading ? (
                  <td
                    colSpan={5}
                    className="w-full justify-center items-center"
                  >
                    <LoadingOverlay visible />
                  </td>
                ) : (
                  timelines?.data?.data?.map((timeline: Timeline) =>{

                    let txt = `${timeline.postedByName} (${timeline?.user?.email})`;

                    return (
                      <tr className="hover:shadow z-20 cursor-pointer text-xs">
                        <td className="text-xs">
                          <HoverCard width={280} shadow="md">
                            <HoverCard.Target>
                              <Text size="sm" className={"cursor-pointer"}>
                                {txt.length > 25
                                  ? txt.substring(0, 22) + "..."
                                  : txt}
                              </Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown
                              className={
                                "text-justify break-all wrap max-h-60 overflow-auto"
                              }
                            >
                              <Text size="sm">{txt}</Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td>
                        <td className="text-xs">{timeline.numOfViews}</td>
                        <td className="text-xs">{timeline.numOfLikes}</td>
                        <td className="text-xs">{timeline.numOfComments}</td>
                        <td className="text-xs">{timeline.numOfShares}</td>
                        <td className="text-xs">{timeline.numOfFavorites}</td>
  
                        <td className="text-xs">
                          {dateFormat(timeline.createdAt)}
                        </td>
                      </tr>
                    );
                  } )
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="h-80 w-full border rounded flex justify-center shadow shadow-sky-100 p-2">
          {categoryGroupedTimelines?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            donut_state_data && (
              <Doughnut options={donut_options} data={donut_state_data} />
            )
          )}
        </div>
      </div>
      <div className="flex space-x-2 justify-between mt-10 shadow">
        <div className="h-92 w-full border rounded flex justify-center shadow-lg shadow-sky-100 p-2">
          {newTimelinesPerMonth?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            bar_state_data && (
              <Bar options={bar_options} data={bar_state_data} />
            )
          )}
        </div>
      </div>
    </div>
  )
}
