import {
  ActionIcon,
  Button,
  HoverCard,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { CollectionQuery, Order } from "../../../models/collection.model"
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model"
import { User } from "../../../models/user.model"
import EntityList from "../../../shared/component/entity-list/entity-list.component"
import { Timeline } from "../../../models/timeline.model"
import {
  useArchiveTimelineMutation,
  useDeleteTimelineMutation,
  useLazyGetFileUrlQuery,
  useLazyGetTimelinesQuery,
  useRestoreTimelineMutation,
  useToggleTimelineStatusMutation,
} from "../store/timeline.query"
import Confirmation from "../../../shared/component/confirmation/action-confirmation"
import {
  IconBan,
  IconEye,
  IconPencil,
  IconPlayerPlay,
  IconRestore,
  IconTrash,
} from "@tabler/icons-react"
import { IconCheck } from "@tabler/icons-react"

import "vidstack/styles/defaults.css"
import "vidstack/styles/community-skin/video.css"

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster
} from "@vidstack/react"
import { TIMELINE_ENDPOINT } from "../timeline.endpoint"
import { Constants } from "../../../shared/constants/Constants"
import { downloadUrlParser } from "../../../shared/utility/Tools/tools"

export default function TimelinePage() {
  const params = useParams()
  const location = useLocation()
  const [viewMode, setViewMode] = useState<entityViewMode>("list")
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [viewModalTitle, setViewModalTitle] = useState<string>("Title")
  const [videoUrl, setVideoUrl] = useState<string>("")
  const [videoFileUrl, setVideoFileUrl] = useState<string>("")
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("")
  const [thumbnailFileUrl, setThumbnailFileUrl] = useState<string>("")
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  })

  const [getTimelines, timelines] = useLazyGetTimelinesQuery();
  const [toggleTimelineStatus, toggleResponse] =
    useToggleTimelineStatusMutation();
  const [archiveTimeline, archiveResponse] = useArchiveTimelineMutation();
  const [restoreTimeline, restoreResponse] = useRestoreTimelineMutation();
  const [deleteTimeline, deleteResponse] = useDeleteTimelineMutation();

  const [getFileUrl, fileUrl] = useLazyGetFileUrlQuery();
  useEffect(() => {
    getTimelines(collection)
  }, [collection])


  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail")
    } else {
      setViewMode("list")
    }
    if (location.pathname === "/user/archived") {
      setViewMode("detail")
    }
  }, [setViewMode, params?.id, location])

  const data: Timeline[] | undefined = timelines.data?.data

  function openViewModal(timeline: Timeline) {
    setViewModal(true)
    setViewModalTitle(timeline.postName)
    // setVideoUrl(`${TIMELINE_ENDPOINT.video}/${timeline.id}`)
    // setThumbnailUrl(`${TIMELINE_ENDPOINT.thumbnail}/${timeline.id}`)
    if (timeline.post) {
      // setVideoUrl(downloadUrlParser(timeline.post))
      setVideoUrl(timeline.postName);
    }
    if (timeline.thumbnail) {
      setThumbnailUrl(timeline.thumbnailFileName)
    }
  }

  useEffect(() => {
    if(videoUrl){
      getFileUrl(videoUrl).then((response:any) => {
        setVideoFileUrl(response.data)
      });
    }
  }, [videoUrl])
  useEffect(() => {
    if(thumbnailUrl){
      getFileUrl(thumbnailUrl).then((response:any) => {
        setThumbnailFileUrl(response.data)
      });
    }
  }, [thumbnailUrl])

  const config: EntityConfig<Timeline> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/user-management/user",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "categoryName", name: "Category" },
      {
        key: "description",
        name: "Description",
        render: (timeline: Timeline) => {
          if (timeline.description) {
            let descriptionArray = timeline?.description?.split("#")
            let description = timeline?.description
            let tags = ""
            if (
              Array.isArray(descriptionArray) &&
              descriptionArray.length > 0
            ) {
              tags = descriptionArray
                ?.slice(1)
                .map((str) => `#${str}`)
                .join(" ")
              description = descriptionArray[0]
            }

            return (
              <HoverCard width={280} shadow="md">
                <HoverCard.Target>
                  <Text size="sm" className={"cursor-pointer"}>
                    {description.length > 25
                      ? description.substring(0, 22) + "..."
                      : description}
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  className={
                    "text-justify break-all wrap max-h-60 overflow-auto"
                  }
                >
                  <Text size="sm">{description}</Text>
                  <Text size="sm" className="text-blue-300">
                    {tags}{" "}
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            )
          } else {
            return ""
          }
        },
      },
      {
        key: "postedByName",
        name: "Posted By",
        render(timeline: Timeline) {
          let txt = `${timeline.postedByName} (${timeline?.postedByEmail})`
          return (
            <HoverCard width={280} shadow="md">
              <HoverCard.Target>
                <Text size="sm" className={"cursor-pointer"}>
                  {txt.length > 25 ? txt.substring(0, 22) + "..." : txt}
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown
                className={"text-justify break-all wrap max-h-60 overflow-auto"}
              >
                <Text size="sm">{txt}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )
        },
      },
      { key: "privacy", name: "Privacy Setting" },
      { key: "numOfViews", name: "Views" },
      { key: "numOfLikes", name: "Likes" },
      { key: "numOfComments", name: "Comments" },
      { key: "numOfShares", name: "Shares" },
      { key: "numOfFavorites", name: "Favorites" },
      {
        key: "archiveReason",
        name: "Archive Reason",
        hideSort: true,
        render: (timeline: Timeline) => {
          if (timeline.archiveReason) {
            return (
              <HoverCard width={280} shadow="md">
                <HoverCard.Target>
                  <Text size="sm" className={"cursor-pointer text-danger"}>
                    {timeline?.archiveReason &&
                    timeline?.archiveReason?.length > 25
                      ? timeline?.archiveReason?.substring(0, 22) + "..."
                      : timeline?.archiveReason}
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown
                  className={
                    "text-justify break-all wrap max-h-60 overflow-auto"
                  }
                >
                  <Text className={"text-danger"} size="sm">
                    {timeline.archiveReason}
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            )
          } else {
            return "Not archived"
          }
        },
      },
      { key: "createdAt", name: "Posted At", isDate: true },
      {
        key: "enabled",
        name: "Status",
      },
      {
        key: "enabled",
        name: "Actions",
        render: (value: Timeline) => {
          return (
            <>
              <div className="flex space-x-1">
                {/* <Button size="sm"> */}
                <Tooltip label="Watch Video">
                  <ActionIcon
                    variant="filled"
                    className="bg-primary text-white"
                    size={"sm"}
                    onClick={(e) => {
                      openViewModal(value)
                    }}
                  >
                    <span>
                      <IconEye size={16} strokeWidth={1.5} />
                    </span>
                  </ActionIcon>
                </Tooltip>
                {/* </Button> */}
                <Confirmation
                  type={"notify"}
                  header={`${
                    value.enabled ? "Block timeline" : "Activate timeline"
                  }`}
                  message={`Are you sure you want to ${
                    value.enabled ? "block" : "activate"
                  } this timeline`}
                  onYes={(val: string) => {
                    toggleTimelineStatus(value.id)
                      .unwrap()
                      .then((res) => {})
                  }}
                  yesText={`${value.enabled ? "Block" : "Activate"}`}
                >
                  <Tooltip
                    label={`${
                      value.enabled ? "Block timeline" : "Activate timeline"
                    }`}
                  >
                    <ActionIcon
                      variant="filled"
                      className={`${
                        value.enabled ? "bg-red-500" : "bg-green-500"
                      } text-white`}
                      size={"sm"}
                      loading={false}
                    >
                      <span>
                        {value.enabled ? (
                          <IconBan size={16} strokeWidth={1.5} />
                        ) : (
                          <IconCheck size={16} strokeWidth={1.5} />
                        )}
                      </span>
                    </ActionIcon>
                  </Tooltip>
                </Confirmation>

                {value.deletedAt ? (
                  <>
                    <Confirmation
                      type={"notify"}
                      header={`${
                        value.deletedAt
                          ? "Restore timeline"
                          : "Archive timeline"
                      }`}
                      message={`Are you sure you want to restore this timeline`}
                      onYes={(val: string) => {
                        restoreTimeline(value.id)
                          .unwrap()
                          .then((res) => {})
                          .catch((err) => {})
                      }}
                      yesText={`Restore`}
                    >
                      <Tooltip label={`Restore Timeline`}>
                        <ActionIcon
                          variant="filled"
                          className={`bg-green-500 text-white`}
                          size={"sm"}
                          loading={false}
                        >
                          <span>
                            <IconRestore size={16} strokeWidth={1.5} />
                          </span>
                        </ActionIcon>
                      </Tooltip>
                    </Confirmation>

                    <Confirmation
                      type={"notify"}
                      header={`Delete timeline`}
                      message={`Are you sure you want to delete this timeline`}
                      onYes={(val: string) => {
                        deleteTimeline(value.id)
                          .unwrap()
                          .then((res) => {})
                          .catch((err) => {})
                      }}
                      yesText={`Delete`}
                    >
                      <Tooltip label={`Delete timeline`}>
                        <ActionIcon
                          variant="filled"
                          className={`bg-red-500 text-white`}
                          size={"sm"}
                          loading={false}
                        >
                          <span>
                            <IconTrash size={16} strokeWidth={1.5} />
                          </span>
                        </ActionIcon>
                      </Tooltip>
                    </Confirmation>
                  </>
                ) : (
                  <Confirmation
                    type={"notify"}
                    result={"single"}
                    resultRequired={true}
                    selectorLabel={"Please select a reason"}
                    labelDescription={"Why are you archiving this timeline?"}
                    options={Constants.ArchiveReason.map((reason) => {
                      return { label: reason, value: reason }
                    })}
                    customInput={true}
                    header={`Archive timeline`}
                    message={`Are you sure you want to archive this timeline`}
                    onYes={(val: string) => {
                      archiveTimeline({ id: value.id, reason: val })
                        .unwrap()
                        .then((res) => {})
                        .catch((err) => {})
                    }}
                    yesText={`Archive`}
                  >
                    <Tooltip label={`Archive timeline`}>
                      <ActionIcon
                        variant="filled"
                        className={`bg-red-500 text-white`}
                        size={"sm"}
                        loading={false}
                      >
                        <span>
                          <IconTrash size={16} strokeWidth={1.5} />
                        </span>
                      </ActionIcon>
                    </Tooltip>
                  </Confirmation>
                )}
              </div>
            </>
          )
        },
      },
    ],
  }

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Timelines"}
        total={timelines?.data?.count}
        showNewButton={false}
        itemsLoading={timelines.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top })
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["user.name", "user.email", "description"],
          })
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data })
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />

      <Modal
        opened={viewModal}
        onClose={() => setViewModal(false)}
        size="70%"
        title={viewModalTitle ?? "Title"}
      >
        <MediaPlayer
          title="Sprite Fight"
          src={videoFileUrl}
          poster={thumbnailFileUrl}
          thumbnails={thumbnailFileUrl}
          aspectRatio={16 / 9}
          crossorigin=""
          
        >
          <MediaOutlet>
            <MediaPoster  alt="Girl walks into sprite gnomes around her friend on a campfire in danger!" />
            {/* <track
              src="https://media-files.vidstack.io/sprite-fight/subs/english.vtt"
              label="English"
              srcLang="en-US"
              kind="subtitles"
              default
            />
            <track
              src="https://media-files.vidstack.io/sprite-fight/chapters.vtt"
              srcLang="en-US"
              kind="chapters"
              default
            /> */}
          </MediaOutlet>
          <MediaCommunitySkin />

        </MediaPlayer>
      </Modal>
    </div>
  )
}
