export const USER_ENDPOINT = {
  detail: `${import.meta.env.VITE_APP_API}/users/get-user`,
  delete: `${import.meta.env.VITE_APP_API}/users/delete-user`,
  toggleStatus: `${import.meta.env.VITE_APP_API}/users/activate-or-block-user`,
  archive: `${import.meta.env.VITE_APP_API}/users/archive-user`,
  restore: `${import.meta.env.VITE_APP_API}/users/restore-user`,
  archivedList: `${import.meta.env.VITE_APP_API}/users/get-archived-users`,
  archivedUser: `${import.meta.env.VITE_APP_API}/users/get-archived-user`,
  list: `${import.meta.env.VITE_APP_API}/users/get-users`,
  create: `${import.meta.env.VITE_APP_API}/users/create-user`,
  update: `${import.meta.env.VITE_APP_API}/users/update-user`,
  updateProfile: `${import.meta.env.VITE_APP_API}/users/update-user-profile-image/`,
  roles: `${import.meta.env.VITE_APP_API}/roles/get-roles`,
  user_role: `${import.meta.env.VITE_APP_API}/accounts/get-user-roles`,
  role_permissions: `${import.meta.env.VITE_APP_API}/roles/get-role-permissions`,
  add_account_role: `${import.meta.env.VITE_APP_API}/accounts/add-account-role`,
  add_account_role_permission: `${import.meta.env.VITE_APP_API}/accounts/add-account-permission`,
 user_role_permissions: `${import.meta.env.VITE_APP_API}/accounts/get-user-permissions-by-role-id`,
};
