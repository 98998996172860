import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Center, Group, PasswordInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  useChangePasswordMutation
} from "../store/account.query";

export default function ChangePasswordPage() {
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      currentPassword: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .required("Current Password is required"),
      password: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .required("New Password is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .test("",'Confirm Password must be the same with the New Password', (value) => {
          if (getValues("password") === value) {
            return true;
          } else {
            return false;
          }
        })
        .required("Confirm Password is required"),
    })
    .required();

  const defaultValue = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };
  const [changePassword, changePasswordResponse] = useChangePasswordMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });

  const onSubmit = (data: any) => {
    changePassword(data).then((response:any) => {
      if (!response?.error) {
        navigate("/");
      }
    });
  };
  const onError = (error: any) => {
    
  };

  return (
    <>
      <div className="bg-sky-200 h-screen w-full flex justify-center items-center">
        <Center className="w-3/4 z-20 px-4 h-3/4 border py-4 bg-gray-700">
          <div className="flex-col space-y-4 w-full">
            <div className="w-full flex justify-center">
              <img src="/cooklikeme.png" alt="img" className=" w-24" />
            </div>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="w-full flex justify-center"
            >
              <div className="w-2/4 gap-y-2">
                <PasswordInput
                  placeholder="Current Password"
                  label="Current Password"
                  classNames={{label:'text-gray-200'}}
                  error={
                    errors?.currentPassword &&
                    `${errors?.currentPassword?.message}`
                  }
                  {...register("currentPassword")}
                  withAsterisk
                />
                <PasswordInput
                  placeholder="New Password"
                  label="New Password"
                  classNames={{label:'text-gray-200'}}
                  error={errors?.password && `${errors?.password?.message}`}
                  {...register("password")}
                  withAsterisk
                />
                <PasswordInput
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  classNames={{label:'text-gray-200'}}
                  description="Confirm password must be the same with the new password"
                  withAsterisk
                  error={
                    errors?.confirmPassword &&
                    `${errors?.confirmPassword?.message}`
                  }
                  {...register("confirmPassword")}
                />

                <div className="w-full flex justify-center items-center">
                  <Group position="center" mt="xl">
                    <Button
                      variant="filled"
                      className="bg-primary w-40 text-white"
                      type="submit"
                      classNames={{ label: "flex space-x-1" }}
                      size={"xs"}
                      loading={changePasswordResponse?.isLoading}
                    >
                      
                      <span>Done</span>
                    </Button>
                  </Group>
                </div>
              </div>
            </form>
          </div>
        </Center>
      </div>
    </>
  );
}
