/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Timeline } from "../../../models/timeline.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { TIMELINE_ENDPOINT } from "../timeline.endpoint";

let timelineCollection: CollectionQuery;
let archivedTimelineCollection: CollectionQuery;

const timelineQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeline: builder.query<Timeline, string>({
      query: (id: string) => ({
        url: `${TIMELINE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedTimeline: builder.query<Timeline, string>({
      query: (id: string) => ({
        url: `${TIMELINE_ENDPOINT.archivedTimeline}/${id}`,
        method: "get",
      }),
    }),

    getArchivedTimelines: builder.query<Collection<Timeline>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${TIMELINE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedTimelineCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getTimelines: builder.query<Collection<Timeline>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${TIMELINE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            timelineCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getFileUrl: builder.query<string, string>({
      query: (postName: string) => ({
        url: `${TIMELINE_ENDPOINT.getFileUrl}/${postName}`,
        method: "GET",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    

    toggleTimelineStatus: builder.mutation<Timeline, string>({
      query: (id: string) => ({
        url: `${TIMELINE_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permission:'manage-timelines'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              timelineQuery.util.updateQueryData(
                "getTimelines",
                timelineCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((timeline) => {
                      if (timeline.id === data.id) return data;
                      else {
                        return timeline;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              timelineQuery.util.updateQueryData("getTimeline", param, (draft) => {
                draft.enabled = data.enabled;
              })
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    archiveTimeline: builder.mutation<Timeline, { id: string; reason: string }>({
      query: (data) => ({
        url: `${TIMELINE_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission:'manage-timelines'
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              timelineQuery.util.updateQueryData(
                "getTimelines",
                timelineCollection,
                (draft) => {

                  draft.data = draft?.data?.filter( timeline => timeline.id !== data.id)
                  draft.count--;
                }
              )
            );
            dispatch(
              timelineQuery.util.updateQueryData(
                "getTimeline",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              timelineQuery.util.updateQueryData(
                "getArchivedTimeline",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
           
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    deleteTimeline: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${TIMELINE_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission:'manage-timelines'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              timelineQuery.util.updateQueryData(
                "getArchivedTimelines",
                archivedTimelineCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((timeline) => timeline.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );

            dispatch(
              timelineQuery.util.updateQueryData(
                "getTimelines",
                timelineCollection,
                (draft) => {
                  draft.data = draft?.data?.filter( timeline => timeline.id !== id)
                  draft.count--;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    restoreTimeline: builder.mutation<Timeline, string>({
      query: (id: string) => ({
        url: `${TIMELINE_ENDPOINT.restore}/${id}`,
        method: "post",
        permission:'manage-timelines'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              timelineQuery.util.updateQueryData(
                "getTimelines",
                timelineCollection,
                (draft) => {
                  draft.data = draft?.data?.map((timeline) => {
                    if (timeline.id === id) {
                      return data;
                    } else {
                      return timeline;
                    }
                  });
                }
              )
            );
            dispatch(
              timelineQuery.util.updateQueryData(
                "getTimeline",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              timelineQuery.util.updateQueryData(
                "getArchivedTimeline",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetTimelineQuery,
  useLazyGetArchivedTimelineQuery,
  useLazyGetTimelinesQuery,
  useLazyGetArchivedTimelinesQuery,

  useLazyGetFileUrlQuery,
  useToggleTimelineStatusMutation,
  useArchiveTimelineMutation,
  useRestoreTimelineMutation,
  useDeleteTimelineMutation,
} = timelineQuery;
