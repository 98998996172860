export const CATEGORY_ENDPOINT = {
  detail: `${import.meta.env.VITE_APP_API}/categories/get-category`,
  list: `${import.meta.env.VITE_APP_API}/categories/get-categories`,
  delete: `${import.meta.env.VITE_APP_API}/categories/delete-category`,
  archive: `${import.meta.env.VITE_APP_API}/categories/archive-category`,
  restore: `${import.meta.env.VITE_APP_API}/categories/restore-category`,
  archivedList: `${import.meta.env.VITE_APP_API}/categories/get-archived-categories`,
  archivedCategory: `${import.meta.env.VITE_APP_API}/categories/get-archived-category`,
  create: `${import.meta.env.VITE_APP_API}/categories/add-category`,
  update: `${import.meta.env.VITE_APP_API}/categories/update-category`,
};
