/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CATEGORY_ENDPOINT } from "../category.endpoint";
import { Category } from "../../../models/category.model";

let categoryCollection: CollectionQuery;
let archivedCategoryCollection: CollectionQuery;

const categoryQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<Category, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCategory: builder.query<Category, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.archivedCategory}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCategories: builder.query<Collection<Category>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CATEGORY_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedCategoryCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getCategories: builder.query<Collection<Category>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CATEGORY_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            categoryCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
   

    createCategory: builder.mutation<Category, Category>({
      query: (newData: any) => ({
        url: `${CATEGORY_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permissions:'manage-categories'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategories",
                categoryCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateCategory: builder.mutation<Category, Category>({
      query: (newData: any) => ({
        url: `${CATEGORY_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission:'manage-categories'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategories",
                categoryCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((category) => {
                      if (category.id === data.id) return data;
                      else {
                        return category;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    

    archiveCategory: builder.mutation<Category, { id: string; reason: string }>({
      query: (data) => ({
        url: `${CATEGORY_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission:'manage-categories'
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategories",
                categoryCollection,
                (draft) => {
                  draft.data = draft?.data?.filter((category) => category.id !== arg.id)
                }
              )
            );
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategory",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              categoryQuery.util.updateQueryData(
                "getArchivedCategory",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
           
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    deleteCategory: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission:'manage-categories'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              categoryQuery.util.updateQueryData(
                "getArchivedCategories",
                archivedCategoryCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((category) => category.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategories",
                categoryCollection,
                (draft) => {
                  draft.data = draft?.data?.filter((category) => category.id !== id)
                  draft.count -= 1;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    restoreCategory: builder.mutation<Category, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.restore}/${id}`,
        method: "post",
        permission:'manage-categories'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategories",
                categoryCollection,
                (draft) => {
                  draft.data = draft?.data?.map((category) => {
                    if (category.id === id) {
                      return data;
                    } else {
                      return category;
                    }
                  });
                }
              )
            );
            dispatch(
              categoryQuery.util.updateQueryData(
                "getCategory",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              categoryQuery.util.updateQueryData(
                "getArchivedCategory",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    
  }),
  overrideExisting: true,
});
export const {
 useLazyGetCategoryQuery,
 useLazyGetCategoriesQuery,
 useLazyGetArchivedCategoryQuery,
 useLazyGetArchivedCategoriesQuery,
 useCreateCategoryMutation,
 useUpdateCategoryMutation,
 useDeleteCategoryMutation,
 useArchiveCategoryMutation,
 useRestoreCategoryMutation
} = categoryQuery;
