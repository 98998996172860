export const TIMELINE_ENDPOINT = {
  detail: `${import.meta.env.VITE_APP_API}/timelines/get-timeline`,
  delete: `${import.meta.env.VITE_APP_API}/timelines/delete-timeline`,
  toggleStatus: `${import.meta.env.VITE_APP_API}/timelines/activate-or-block-timeline`,
  archive: `${import.meta.env.VITE_APP_API}/timelines/archive-timeline`,
  restore: `${import.meta.env.VITE_APP_API}/timelines/restore-timeline`,
  archivedList: `${import.meta.env.VITE_APP_API}/timelines/get-archived-timelines`,
  archivedTimeline: `${import.meta.env.VITE_APP_API}/timelines/get-archived-timeline`,
  list: `${import.meta.env.VITE_APP_API}/timelines/get-timelines-dashboard`,
  create: `${import.meta.env.VITE_APP_API}/timelines/create-timeline`,
  update: `${import.meta.env.VITE_APP_API}/timelines/update-timeline`,
  video: `${import.meta.env.VITE_APP_API}/timelines/get-timeline-video-stream`,
  thumbnail: `${import.meta.env.VITE_APP_API}/timelines/get-timeline-video-thumbnail`,
  getFileUrl: `${import.meta.env.VITE_APP_API}/timelines/get-file-url`,
};
