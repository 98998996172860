import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, Switch, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Configuration } from "../../../models/configuration.model";
import Card from "../../../shared/component/Card/card-component";
import {
  useLazyGetConfigurationQuery,
  useUpdateConfigurationMutation,
} from "../store/configuration.query";

export default function ConfigurationPage() {
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      globalConfigurations: yup.object().shape({
        numOfReportsToBlock: yup
          .number()
          .typeError("Number of reports to block should be a number")
          .required("Number of reports to block is required"),
      }),
    })
    .required();

  const defaultValue: Configuration = {
    globalConfigurations: {
      numOfReportsToBlock: 5,
    },
  };
  const [getConfiguration, configuration] = useLazyGetConfigurationQuery();
  const [updateConfiguration, updateConfigurationResponse] =
    useUpdateConfigurationMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });

  const onSubmit = (data: any) => {

    console.log(data.globalConfigurations);
    updateConfiguration({ id: configuration?.data?.id ?? "", globalConfigurations: data.globalConfigurations })
      .unwrap()
      .then(() => {})
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getConfiguration("").unwrap().then((response: Configuration) => {
      setValue("globalConfigurations.numOfReportsToBlock", response.globalConfigurations.numOfReportsToBlock);
   
    }).catch((e: Error) => {

    });

  }, [getConfiguration]);
  const onError = (error: any) => {
    console.log("Error", error);
  };

  const config: Configuration | undefined = configuration?.data;

  return (
    <div className="h-full p-4">
      <Card title={"Configuration"}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="w-full gap-y-4 dark:text-white m-2 p-2"
        >
          <div className="flex-col space-y-4">
            <div className="flex w-full space-x-4  justify-start">
            <TextInput
              placeholder="Number of reports to block"
              className="w-1/2"
              label="Number of reports to block"
              error={
                errors?.globalConfigurations?.numOfReportsToBlock &&
                `${errors?.globalConfigurations?.numOfReportsToBlock?.message}`
              }
              {...register("globalConfigurations.numOfReportsToBlock")}
              withAsterisk
            />
            </div>
            
            <div className="w-full flex justify-start items-center">
              <Group position="left" mt="xl">
                <Button
                  variant="filled"
                  className="bg-primary w-40 text-white"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={updateConfigurationResponse?.isLoading}
                >
                  <span>Update</span>
                </Button>
              </Group>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}
