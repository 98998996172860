import { Avatar, Group, Menu, Text, UnstyledButton } from "@mantine/core"
import {
  IconChevronRight,
  IconDashboard,
  IconEdit,
  IconReportAnalytics,
  IconSettings,
  IconUserCircle,
  IconVideo,
} from "@tabler/icons-react"
import { forwardRef, useContext, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { NavLink, useLocation } from "react-router-dom"
import {
  useLazyGetEmployeeRolePermissionsQuery,
} from "../../feature/employee/store/employee.query"
import { Role } from "../../models/role.model"
import { switchRole } from "../../shared/auth/api/auth.api"
import { setRole } from "../../shared/auth/auth-slice/auth-slice"
import AuthContext from "../../shared/auth/context/authContext"
import { downloadUrlParser } from "../../shared/utility/Tools/tools"
import { LinkDropDown } from "./link-dropdown-component"
import { useLazyGetUserRolesQuery } from "../../feature/user/store/user.query"
interface Props {
  collapse: boolean
}
interface UserButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  image: string
  name: string
  role: string | undefined
  icon?: React.ReactNode
  collapse: boolean
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, role, collapse, icon, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: "block",
        width: "100%",
      })}
      {...others}
    >
      <Group className="flex">
        <Avatar src={image} radius="xl" />

        {!collapse && (
          <div style={{ flex: 1 }}>
            <Text size="sm" className="text-white" weight={500}>
              {name}
            </Text>

            <Text
              color="dimmed"
              size="xs"
              className="capitalize flex space-x-1 items-end"
            >
              {role} {!collapse && (icon || <IconChevronRight size="1rem" />)}
            </Text>
          </div>
        )}
      </Group>
    </UnstyledButton>
  ),
)

export default function SideNav(props: Props) {
  const ref = useRef<any>(null)
  const location = useLocation()
  const dispatch = useDispatch()
  const { user, logOut } = useContext(AuthContext)
  const [value, setValue] = useState<string | null>(null)
  const [currentRole, setCurrentRole] = useState<Role>()

  const [getUserRoles, userRoles] = useLazyGetUserRolesQuery()
  const [getUserRolePermissions, userRolePermissions] =
    useLazyGetEmployeeRolePermissionsQuery()

  const { collapse } = props

  const validateJson = () => {
    if (localStorage?.currentRole) {
      try {
        return JSON.parse(localStorage.currentRole)?.id
      } catch (e) {
        return null
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setValue(null);
      }
    }
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [setValue])

  useEffect(() => {
    if (user?.id) {
      getUserRoles(user?.id, true).then((response) => {
        if (response) {
          if (
            localStorage.currentRole &&
            response?.data?.data?.some((role) => role.id === validateJson())
          ) {
            setCurrentRole(JSON.parse(localStorage?.currentRole))
          } else {
            setCurrentRole(response?.data?.data?.[0])

            localStorage.setItem(
              "currentRole",
              JSON.stringify(response?.data?.data?.[0]),
            )
          }
        }
      })
    }
  }, [user, getUserRoles])

  useEffect(() => {
    if (currentRole?.id) {
      dispatch(setRole(currentRole))
      getUserRolePermissions({
        roleId: currentRole?.id,
        accountId: user?.id,
      }).then((response) => {
        if (response) {
          localStorage.setItem(
            "userRolePermissions",
            JSON.stringify(response?.data),
          )
        }
      })
    }
  }, [currentRole, getUserRolePermissions, user?.id])

  return (
    <div className="w-full h-full relative  flex-col bg-gray-900 border-0 text-white  border-r border-gray-500">
      <div className={`gap-4 overflow-y-auto  h-full pb-2`}>
        <Menu withArrow>
          <Menu.Target>
            <UserButton
              className={` bg-gray-900 mt-2 mb-4 ${
                collapse ? " flex justify-center " : ""
              }`}
              collapse={collapse}
              image={
                user?.profileImage?.path
                  ? downloadUrlParser({
                      path: user?.profileImage?.path,
                      filename: user?.profileImage?.filename,
                      originalname: user?.profileImage?.originalname,
                      mimetype: user?.profileImage?.mimetype,
                    })
                  : ""
              }
              name={user?.name}
              role={currentRole?.name}
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Profile</Menu.Label>
            {userRoles?.data?.data?.map((role: Role) => (
              <Menu.Item
              key={role.id}
                icon={<IconUserCircle size={14} />}
                onClick={() => {
                  setCurrentRole(role)
                  switchRole(role?.id)
                  localStorage.setItem("currentRole", JSON.stringify(role))
                }}
              >
                {role?.name}
              </Menu.Item>
            ))}
            <Menu.Item
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current w-4"
                  viewBox="0 0 24 24"
                  fill="#5B5B5B"
                >
                  <path
                    d="M6 2C4.897 2 4 2.897 4 4L4 20C4 21.103 4.897 22 6 22L18 22C19.103 22 20 21.103 20 20L20 15.25L18.001953 16.748047L18.001953 20L6 20L6 4L18 4L18 7.25L20 8.75L20 4C20 2.897 19.103 2 18 2L6 2 z M 16 8.25L16 11L11 11L11 13L16 13L16 15.75L21 12L16 8.25 z"
                    fill="#5B5B5B"
                  />
                </svg>
              }
            >
              <div onClick={() => logOut()} className="w-full  cursor-pointer">
                Sign out
              </div>
            </Menu.Item>
            <Menu.Item icon={<IconEdit size={14} />}>
              <NavLink to={"/accounts/change-password"}>
                Change Password
              </NavLink>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <div className="flex-col space-y-1 " ref={ref}>
          <LinkDropDown
            label="Dashboard"
            icon={IconDashboard}
            link="/"
            collapse={collapse}
            links={[
              {
                label: "Timeline",
                link: "/",
                Icon: IconSettings,
                active:
                  location?.pathname == "/" && true,
              },
              {
                label: "User",
                link: "/dashboard/user",
                Icon: IconSettings,
                active:
                  location?.pathname?.includes(
                    "/dashboard/user",
                  ) && true,
              },
            ]}
          />

          {["super_admin", "admin"].includes(`${currentRole?.key}`) && (
            <>
              <LinkDropDown
                label="Timeline"
                icon={IconVideo}
                collapse={collapse}
                link="/timeline"
                active={location?.pathname?.includes("/timeline") && true}
              />
              <LinkDropDown
                label="Activity"
                icon={IconReportAnalytics}
                collapse={collapse}
                link="/activity"
                active={location?.pathname?.includes("/activity") && true}
              />
            </>
          )}
          {currentRole?.key !== "finance" && (
            <LinkDropDown
              label="Settings"
              icon={IconSettings}
              collapse={collapse}
              links={[
                {
                  label: "Category",
                  link: "/settings-and-configurables/category",
                  Icon: IconSettings,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/category",
                    ) && true,
                },
                {
                  label: "Configuration",
                  link: "/settings-and-configurables/configurable",
                  Icon: IconSettings,
                  active:
                    location?.pathname?.includes(
                      "/settings-and-configurables/configurable",
                    ) && true,
                },
              ]}
            />
          )}
          {currentRole?.key === "super_admin" && (
            <LinkDropDown
              label="User Management"
              icon={IconUserCircle}
              collapse={collapse}
              links={[
                {
                  label: "Employee",
                  link: "/employee-management/employee",
                  Icon: IconUserCircle,
                  active:
                    location?.pathname?.includes(
                      "/employee-management/employee",
                    ) && true,
                },
                {
                  label: "User",
                  link: "/user-management/user",
                  Icon: IconUserCircle,
                  active:
                    location?.pathname?.includes("/user-management/user") &&
                    true,
                },
                {
                  label: "Role Management",
                  link: "/user-management/role",
                  Icon: IconUserCircle,
                  active:
                    location?.pathname?.includes("/user-management/role") &&
                    true,
                },
              ]}
            />
          )}

          <div className="h-14 px-2  flex justify-center mt-8  items-center">
            <img
              src="/cooklikeme.png"
              alt="logo"
              className={`${collapse ? "h-4" : "h-10"}`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
