import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import UserPage from "../page/timeline-page";
export const TimelineRoute: RouteObject = {
  path: "/timeline",
  element: (
    <AuthGuard role={["super_admin"]}>
      <UserPage />
    </AuthGuard>
  ),
};
