/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Permission } from "../../../models/permission.model";
import { Role } from "../../../models/role.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { ROLE_ENDPOINT } from "../role.endpoint";

let rolePermissionCollection: any;
const roleQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<Collection<Role>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROLE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
        permission:'manage-roles'
      }),
    }),
    getPermissions: builder.query<Collection<Permission>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROLE_ENDPOINT.permission}`,
        method: "GET",
        params: collectionQueryBuilder(data),
        permission:'manage-permissions'
      }),
    }),
    getRolePermissions: builder.query<Collection<Permission>, any>({
      query: (data: { roleId: string; collection: CollectionQuery }) => ({
        url: `${ROLE_ENDPOINT.role_permissions}/${data.roleId}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          await queryFulfilled
          rolePermissionCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createRolePermissions: builder.mutation<Permission[], any>({
      query: (data: { roleId: string; permissions: string[] }) => ({
        url: `${ROLE_ENDPOINT.create_role_permissions}`,
        method: "POST",
        data: data,
        permission:'manage-permissions'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              roleQuery.util.updateQueryData(
                "getRolePermissions",
                rolePermissionCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(...data);
                    draft.count += data.length;
                  }
                }
              )
            );
            notification("success", "Successfully assigned");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    removeRolePermissions: builder.mutation<any, any>({
      query: (data: { roleId: string; permissionId: string }) => ({
        url: `${ROLE_ENDPOINT.remove_role_permissions}`,
        method: "DELETE",
        data: data,
        permission:'manage-permissions'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              roleQuery.util.updateQueryData(
                "getRolePermissions",
                rolePermissionCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (permission) => permission.id !== param.permissionId
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully removed");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetRolesQuery,
  useLazyGetPermissionsQuery,
  useLazyGetRolePermissionsQuery,
  useCreateRolePermissionsMutation,
  useRemoveRolePermissionsMutation,
} = roleQuery;
