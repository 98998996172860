/* eslint-disable @typescript-eslint/no-explicit-any */
import { Activity } from "../../../models/activity.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Employee } from "../../../models/employee.model";
import { User } from "../../../models/user.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { ACTIVITY_LOG_ENDPOINT } from "../activity.endpoint";

let activityLogCollection: CollectionQuery;
let archivedActivityCollection: CollectionQuery;
let accountCollection: CollectionQuery;
const activityQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivity: builder.query<Activity, string>({
      query: (id: string) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),
    getAccounts: builder.query<Collection<User|Employee>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.getAccounts}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            accountCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),


    getArchivedActivity: builder.query<Activity, string>({
      query: (id: string) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.archivedActivity}/${id}`,
        method: "get",
      }),
    }),

    getArchivedActivities: builder.query<Collection<Activity>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, {queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedActivityCollection = param;
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'error try again');
        }
      },
    }),

    getActivities: builder.query<Collection<Activity>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, {queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            activityLogCollection = param;
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'error try again');
        }
      },
    }),

    archiveActivity: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.archive}/${id}`,
        method: "delete",
        permission:'admin'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {

        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
                activityQuery.util.updateQueryData(
                    "getActivities",
                    activityLogCollection,
                    (draft) => {
                      if (data) {
                        draft.data = draft.data.filter(
                            (activityLog) => activityLog.id !== id
                        );
                        draft.count -= 1;
                      }
                    }
                )
            );
            
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    restoreActivity: builder.mutation<Activity, string>({
      query: (id: string) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.restore}/${id}`,
        method: "post",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
                activityQuery.util.updateQueryData(
                    "getArchivedActivities",
                    archivedActivityCollection,
                    (draft) => {
                      if (data) {
                        draft.data = draft.data.filter(
                            (activityLog) => activityLog.id !== id
                        );
                        draft.count -= 1;
                      }
                    }
                )
            );

            dispatch(
                activityQuery.util.updateQueryData(
                    "getActivities",
                    activityLogCollection,
                    (draft) => {
                      if (data) {
                        draft.data.push(data);
                        draft.count += 1;
                      }
                    }
                )
            );

          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    deleteActivity: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ACTIVITY_LOG_ENDPOINT.delete}/${id}`,
        method: "delete",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
                activityQuery.util.updateQueryData(
                    "getArchivedActivities",
                    archivedActivityCollection,
                    (draft) => {
                      if (data) {
                        draft.data = draft.data.filter(
                            (activityLog) => activityLog.id !== id
                        );
                        draft.count -= 1;
                      }
                    }
                )
            );
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

  }),
  overrideExisting: true,
});
export const {
  useLazyGetActivityQuery,
  useLazyGetArchivedActivityQuery,
  useLazyGetActivitiesQuery,
  useLazyGetAccountsQuery,
  useLazyGetArchivedActivitiesQuery,
  useArchiveActivityMutation,
  useRestoreActivityMutation,
  useDeleteActivityMutation,
} = activityQuery;
