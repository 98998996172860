import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import UserDashboardPage from "../page/user-dashboard-page";
import TimelineDashboardPage from "../page/timeline-dashboard-page";

const UserDashboardRoute: RouteObject = {
  path: "/dashboard/user",
  element: (
    <AuthGuard>
      <UserDashboardPage />
    </AuthGuard>
  ),
};
const TimelineDashboardRoute: RouteObject = {
  path: "/",
  element: (
    <AuthGuard>
      <TimelineDashboardPage />
    </AuthGuard>
  ),
};
// const DashboardRoute: RouteObject = {
//   path: "/",
//   element: (
//     <AuthGuard>
//       <DashBoardComponent />
//     </AuthGuard>
//   ),
// };

export {
  
  UserDashboardRoute,
  TimelineDashboardRoute,
  // DashboardRoute
};

