/* eslint-disable @typescript-eslint/no-explicit-any */

import { CollectionQuery } from "../../../models/collection.model";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { DASHBOARD_ENDPOINT } from "../dashboard.endpoint";

const dashboardQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    
    groupUsersByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_users_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getTrendingTimelines: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.get_timelines}`,
        method: "GET"
      }),
    }),
    groupTimelinesByStatus: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_timelines_by_status}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    numberOfNewUsersPerMonth: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.number_of_new_users_per_month}`,
        method: "GET",
      }),
    }),
    numberOfNewTimelinesPerMonth: builder.query<any, void>({
      query: () => ({
        url: `${DASHBOARD_ENDPOINT.number_of_new_timelines_per_month}`,
        method: "GET",
      }),
    }),
    groupUsersByGender: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_users_by_gender}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    groupTimelinesByCategory: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.group_timelines_by_category}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    
    exportUsers: builder.query<any, CollectionQuery>({
      query: (data:CollectionQuery) => ({
        url: `${DASHBOARD_ENDPOINT.export_users}?${collectionQueryBuilder(data)}`,
        method: "GET",
        responseType: 'arraybuffer'
      }),
    }),
   
  }),
  overrideExisting: true,
});
export const {

  useLazyGroupUsersByGenderQuery,
  useLazyGroupUsersByStatusQuery,

  useLazyExportUsersQuery,
  useLazyNumberOfNewUsersPerMonthQuery,

  useLazyGroupTimelinesByCategoryQuery,
  useLazyGroupTimelinesByStatusQuery,
  useLazyNumberOfNewTimelinesPerMonthQuery,

  useLazyGetTrendingTimelinesQuery
} = dashboardQuery;
