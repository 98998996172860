export const EMPLOYEE_ENDPOINT = {
  detail: `${import.meta.env.VITE_APP_API}/employees/get-employee`,
  delete: `${import.meta.env.VITE_APP_API}/employees/delete-employee`,
  toggleStatus: `${import.meta.env.VITE_APP_API}/employees/activate-or-block-employee`,
  archive: `${import.meta.env.VITE_APP_API}/employees/archive-employee`,
  restore: `${import.meta.env.VITE_APP_API}/employees/restore-employee`,
  archivedList: `${import.meta.env.VITE_APP_API}/employees/get-archived-employees`,
  archivedEmployee: `${import.meta.env.VITE_APP_API}/employees/get-archived-employee`,
  list: `${import.meta.env.VITE_APP_API}/employees/get-employees`,
  create: `${import.meta.env.VITE_APP_API}/employees/create-employee`,
  update: `${import.meta.env.VITE_APP_API}/employees/update-employee`,
  updateProfile: `${import.meta.env.VITE_APP_API}/employees/update-employee-profile-image/`,
  roles: `${import.meta.env.VITE_APP_API}/roles/get-roles`,
  user_role: `${import.meta.env.VITE_APP_API}/accounts/get-user-roles`,
  role_permissions: `${import.meta.env.VITE_APP_API}/roles/get-role-permissions`,
  add_account_role: `${import.meta.env.VITE_APP_API}/accounts/add-account-role`,
  add_account_role_permission: `${import.meta.env.VITE_APP_API}/accounts/add-account-permission`,
 user_role_permissions: `${import.meta.env.VITE_APP_API}/accounts/get-user-permissions-by-role-id`,
 remove_account_role: `${import.meta.env.VITE_APP_API}/accounts/remove-account-role`,
};
