import {
  LoadingOverlay,
  Stack,
  Title,
  Accordion,
} from "@mantine/core";
import { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import Card from "../../../shared/component/Card/card-component";
import { useLazyGetActivityQuery } from "../store/activity.query";
import {dateFormat} from "../../../shared/utility/date-format/date-format";

export interface Props {
  editMode: string;
}

export default function ActivityDetailComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();

  const [getActivity, activity] = useLazyGetActivityQuery();

  const [, setActivityId] = useState("");
  const [data, setData] = useState<any>(undefined);

  function parseData(data: any){

    if(typeof data === 'boolean'){
      return data + "";
    }
    if(((typeof data) === (typeof "string"))){
      return data;
    }
    else if((typeof data) === (typeof [])){
      return JSON.stringify(data, null, "\t");
    }

  }

  useEffect(() => {
    if (editMode === "detail") {
      getActivity(`${params?.id}`).then((response: any) => {
        setActivityId(response.data.id);
        if(response.data.action === "Update"){
          const payload = response.data.payload;
          const oldPayload = response.data.oldPayload;
          const _data:any = {};
          const keys = new Set([...Object.keys(payload), ...Object.keys(oldPayload)]);
          keys.forEach((key) => {
            _data[key] = {_new: payload[key] ?? "      --      ", _old: oldPayload[key] ?? "      --      "}
          });
          setData(_data);
        }
      });
    }
  }, [params.id, editMode, getActivity]);

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      {activity.isLoading && <LoadingOverlay visible />}
      <Card
        title={
          editMode === "new"
            ? "New Activity"
            : activity?.data?.action + " on " + activity?.data?.modelName
        }
      >
        <LoadingOverlay
          visible={editMode === "new" ? false : activity.isFetching}
        />
        <div className="flex  justify-center h-full">
          <div className={"w-full gap-y-4 dark:text-white"}>
            <Accordion
              defaultValue={["detail", "change"]}
              multiple
              variant="separated"
              classNames={{ control: "bg-secondary text-gray-700" }}
            >
              <Accordion.Item value="detail">
                <Accordion.Control>Detail</Accordion.Control>
                <Accordion.Panel>
                  <div className="flex-col space-y-4">
                    <div className="flex w-full space-x-4  justify-between">
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Action
                        </Title>
                        <Title order={5}>{activity?.data?.action}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Model
                        </Title>
                        <Title order={5}>{activity?.data?.modelName}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                            order={6}
                            className={"text-gray-500 dark:text-white"}
                        >
                          By
                        </Title>
                        <Title order={5}>{activity?.data?.user?.name}</Title>
                      </Stack>
                    </div>
                    <div className="flex w-full space-x-4  justify-between">

                      <Stack spacing="xs">
                        <Title
                            order={6}
                            className={"text-gray-500 dark:text-white"}
                        >
                          Model ID
                        </Title>
                        <Title order={5}>{activity?.data?.modelId}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Action performed at
                        </Title>
                        <Title order={5}>{dateFormat(
                            activity?.data?.createdAt,
                        )}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                            order={6}
                            className={"text-gray-500 dark:text-white"}
                        >
                          IP
                        </Title>
                        <Title order={5}>{activity?.data?.ip ?? "--"}</Title>
                      </Stack>
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>

              {data ? (
                  <Accordion.Item value="change">
                    <Accordion.Control>Change</Accordion.Control>
                    <Accordion.Panel>
                      <div className="relative overflow-x-auto relative">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="py-3 px-6">
                              Key
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Old Data
                            </th>
                            <th scope="col" className="py-3 px-6">
                              New Data
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          {Object.keys(data).map((key:string, id:number) => (
                              <tr key={id} className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700">
                                <td scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                  {key}
                                </td>
                                <td scope="row" className="w-40 py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                  {parseData(data[key]['_old'])}
                                </td>
                                <td scope="row" className="w-40 py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                  {parseData(data[key]['_new'])}
                                </td>
                              </tr>
                          ))}
                          </tbody>


                        </table>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
              ) : (
                <></>
              )}
            </Accordion>
          </div>
        </div>
      </Card>
    </div>
  );
}
