/* eslint-disable @typescript-eslint/no-explicit-any */
import { Activity } from "../../../models/activity.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { appApi } from "../../../store/app.api";
import { collectionQueryBuilder } from "../../utility/collection-builder/collection-query-builder";

const sharedQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query<Collection<Activity>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${import.meta.env.VITE_APP_API}/activities/get-activities`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch {
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
useLazyGetActivitiesQuery} = sharedQuery;
