import { yupResolver } from "@hookform/resolvers/yup"
import {
  Accordion,
  Button,
  Group,
  LoadingOverlay,
  Tabs,
  TextInput,
  Textarea,
} from "@mantine/core"
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react"
import { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import Confirmation from "../../../shared/component/confirmation/action-confirmation"
import {
  useArchiveCategoryMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useLazyGetArchivedCategoryQuery,
  useLazyGetCategoryQuery,
  useRestoreCategoryMutation,
  useUpdateCategoryMutation,
} from "../store/category.query"

import AuthContext from "../../../shared/auth/context/authContext"
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component"
import { Constants } from "../../../shared/constants/Constants"
import { Category } from "../../../models/category.model"

export interface Props {
  editMode: string
}

export default function NewCategoryComponent(props: Props) {
  const { editMode } = props
  const params = useParams()
  const navigate = useNavigate()

  const defaultValue: Category = {
    id: "",
    name: "",
    description: "",
  }

  const nameRegEx =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s'\-]*)$/gi
  let schema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should have at least 5 characters")
      .max(100, "Name should not be more than 100 characters")
      .matches(nameRegEx, "Remove invalid characters")
      .required("Name is required"),
  })

  const Auth = useContext(AuthContext)
  const [getCategory, category] = useLazyGetCategoryQuery()
  const [getArchivedCategory, archivedCategory] =
    useLazyGetArchivedCategoryQuery()
  const [createCategory, newCategory] = useCreateCategoryMutation()
  const [updateCategory, updateResponse] = useUpdateCategoryMutation()

  const [archiveCategory, archiveResponse] = useArchiveCategoryMutation()
  const [restoreCategory, restoreCall] = useRestoreCategoryMutation()
  const [deleteCategory, deleteCall] = useDeleteCategoryMutation()


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Category>({
    resolver: yupResolver(schema),
    mode: "all",
  })

  function resetForm() {
    reset(defaultValue)
  }

  useEffect(() => {
    if (editMode === "detail") {
      reset(defaultValue)

      if (params?.type === "archived") {
        getArchivedCategory(`${params?.id}`).then((response: any) => {
          reset(response.data)
        })
      } else {
        getCategory(`${params?.id}`).then((response: any) => {
          reset(response.data)
        })
      }
    } else {
      resetForm()
    }
  }, [params.id, editMode, getCategory, updateCategory])

  function onSubmit(data: any) {
    if (editMode === "new") {
      createCategory(data)
        .unwrap()
        .then((result: any) => {
          if (result) {
            reset(defaultValue)
          }
        })
    } else {
      const category: Category = {
        id: params.id,
        ...data,
      }

      updateCategory(category)
        .unwrap()
        .then((response: any) => {})
    }
  }

  const onError = (errors: any, e: any) => console.log(errors, e)

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Category" : category?.data?.name ?? ""}
        item={category}
        path={`/settings-and-configurables/category/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (category.isFetching || updateResponse.isLoading|| archiveResponse.isLoading || newCategory.isLoading || archivedCategory.isFetching)
          }
        />

        <Tabs defaultValue="main">
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex  justify-center h-full">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <Accordion
                  defaultValue={["main"]}
                  multiple
                  variant="separated"
                  classNames={{ control: "bg-secondary text-gray-700" }}
                >
                  <Accordion.Item value="main">
                    <Accordion.Control>Category</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.name && `${errors?.name?.message}`}
                            withAsterisk
                            placeholder="Name"
                            label="Name"
                            {...register("name")}
                          />

                          <div className="w-full">
                            <Textarea
                              classNames={{ label: "dark:text-white " }}
                              className="w-full"
                              error={
                                errors.description &&
                                `${errors?.description?.message}`
                              }
                              label="Description"
                              placeholder="description"
                              mt="sm"
                              {...register("description")}
                            />
                          </div>
                        </div>
                        
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <div className="w-full flex justify-end items-center">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                          onClick={() => {
                            resetForm()
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current h-6"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="bg-primary text-white"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={newCategory.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}
                    {editMode === "detail" && (
                      <>
                        {(params?.type === "archived" &&
                          archivedCategory?.data?.deletedAt) ||
                        (params?.type === "active" &&
                          category?.data?.deletedAt) ? (
                          <>
                            <Confirmation
                              type={"danger"}
                              message={
                                "Are you sure you want to delete it permanently?"
                              }
                              onYes={() => {
                                deleteCategory(`${params?.id}`);
                                navigate(-1);
                              }}
                              header={`Permanent Delete confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={deleteCall.isLoading}
                                className={`bg-danger text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconTrash size={20} strokeWidth={1.5} />
                                <span>Delete</span>{" "}
                              </Button>
                            </Confirmation>
                            <Confirmation
                              type={"notify"}
                              message={`Are you sure you want to restore it`}
                              onYes={() => restoreCategory(`${params?.id}`)}
                              header={`Restore confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={restoreCall.isLoading}
                                className={`bg-green-500 text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconArrowBackUp size={20} strokeWidth={1.5} />
                                <span>Restore</span>
                              </Button>
                            </Confirmation>
                          </>
                        ) : (
                          <>
                            {Auth.user.id !== category?.data?.id && (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this category?"
                                  }
                                  header={"Archive Confirmation"}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this category?"
                                  }
                                  options={Constants.ArchiveReason.map(
                                    (reason: string) => {
                                      return { label: reason, value: reason }
                                    },
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(data: string) => {
                                    archiveCategory({
                                      id: `${params.id}`,
                                      reason: data,
                                    });
                                    navigate(-1);
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="bg-danger text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>

                                
                                <Button
                                  variant="filled"
                                  className="bg-primary text-white"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateResponse.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  )
}
