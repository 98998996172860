
const  ArchiveReason: string[] = [
  "No Longer Needed",
  "Completed or Finalized", 
  "Duplicate Entry",
  "Historical Reference",
  "Legal or Compliance Requirement",
  "Data Quality Issue",
  "Business Rule Change",
  "User Requested",
  "Inactive or Dormant",
  "It is incorrect and can't update it",
];
export const Constants = {
  ArchiveReason: [...ArchiveReason],
  
  UserArchiveReason:[
    ...ArchiveReason,
    "User has been terminated",
    "User has resigned"
  ],
  

};



export const decodeMonth = (month: string) => {
  switch (month) {
    case '01':
      return "January";
    case '02':
      return "February";
    case '03':
      return "March";
    case '04':
      return "April";
    case '05':
      return "May";
    case '06':
      return "June";
    case '07':
      return "July";
    case '08':
      return "August";
    case '09':
      return "September";
    case '10':
      return "October";
    case '11':
      return "November";
    default:
      return "December";
  }
}
