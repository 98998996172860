/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, PasswordInput, TextInput } from "@mantine/core"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import * as yup from "yup"
import { RootState } from "../../../../store/app.store"
import AuthContext from "../../context/authContext"
interface Account {
  email: string
  password: string
}
function Login() {
  const { login } = useContext(AuthContext)
  const loading = useSelector((state: RootState) => state.authReducer.loading)
  const schema = yup
    .object({
      email: yup.string().email().required("Email is required"),
      password: yup
        .string()
        .min(8, "Password length must be greater than 8")
        .max(25, "Password length must be 25 and less")
        .required("password"),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm<Account>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  function onSubmit(data: Account) {
    login(data)
  }

  const onError = (error: any) => {
    console.log("Error", error)
  }

  return (
    <div className="relative flex min-h-screen justify-center bg-sky-200 ml-[-250px] p-2 lg:p-8  ">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 400 300"
        width="406"
        height="306"
        className="illustration styles_illustrationTablet__1DWOa"
      >
        <title>_</title>
        <path
          d="M335.57,139.24c-9.78-36.4-29.53-59.9-62.55-67.5-45.71-10.51-103.5-11.08-147.72-.49-16.47,4-33,11.3-44.48,25.54-21.24,26.39-27,84.57-6.76,113.71,9,13,24,17.83,38.18,20.75,53.88,11.13,109.14,5,163.73.35,12.65-1.07,25.84-2.23,36.76-9.7C335.9,206.06,343.13,167.35,335.57,139.24Z"
          fill="#dddbdb"
          opacity="0.18"
        ></path>
        <path
          d="M130.26,257.42a21,21,0,0,1-21-21V101.18c-11.72-7.21-14.33-15-14.33-20.4,0-8.3,5.86-20.05,33.8-28.85,19.15-6,44.46-9.35,71.27-9.35s52.12,3.32,71.27,9.35c27.94,8.8,33.8,20.55,33.8,28.85,0,5.45-2.61,13.19-14.33,20.4V236.42a21,21,0,0,1-21,21Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M200,50c-53.91,0-97.62,13.77-97.62,30.75,0,5.88,5.24,11.37,14.33,16v139.6A13.55,13.55,0,0,0,130.26,250H269.74a13.55,13.55,0,0,0,13.55-13.55V96.82c9.09-4.67,14.33-10.16,14.33-16C297.62,63.8,253.91,50,200,50Z"
          fill="#fff"
          opacity="0.3"
        ></path>
        <path
          d="M175.68,248.67a4.15,4.15,0,0,1-2,1.18c-1.95.68-3.91,1.31-5.88,1.91l-1.77.52q-3.18.93-6.41,1.71c-.47.11-.93.21-1.39.33l-.42.09a2.63,2.63,0,0,1-1.5,0,3.09,3.09,0,0,1-1.42-1.72,31.45,31.45,0,0,1-2.77-11.52c-.27-5.81.76-12.54-3.38-16.62-2-2-5-2.94-6.6-5.26-2.24-3.23-1-7.57-.79-11.48a19.63,19.63,0,0,0-6.77-15.6c-1.67-1.42-3.63-2.59-4.82-4.44a11.85,11.85,0,0,1-1.56-4.74c-1-7,.2-14.63-3.12-20.9-2.51-4.75-7.33-8-9.51-12.93-3.06-6.9-.37-15.76-4.81-21.86-1.79-2.45-4.51-4.08-6.41-6.46s-2.93-5.59-3.84-8.62a3.31,3.31,0,0,1,0-2.84,2.74,2.74,0,0,1,1.14-.84l.31-.14c.44-.19.89-.4,1.37-.64,2-1,4.2-2.24,6.41-3.26.56-.26,1.12-.51,1.68-.73a15.54,15.54,0,0,1,3.7-1.06c3.73-.49,5.67,2.83,7.78,5.66a50.7,50.7,0,0,1,9.45,22c.65,3.92.87,8,2.79,11.51,2.85,5.18,8.88,7.91,12,12.91,5.19,8.19,1.16,19.68,5.83,28.19,2.53,4.59,7.3,7.69,9.59,12.4,3.91,8.05-.35,18.64,4.58,26.1,1.2,1.79,2.83,3.24,4.14,5,4.14,5.38,4.6,12.63,4.89,19.42A4.27,4.27,0,0,1,175.68,248.67Z"
          fill="#24285b"
        ></path>
        <path
          d="M159.62,247.72c-.06,2-.15,4.16,0,6.27-.47.11-.93.21-1.39.33l-.42.09a63.5,63.5,0,0,1,0-7c.25-7.27-.07-14.73-2.81-21.58-2.25-5.6-6.58-10-9-15.49a18.56,18.56,0,0,1-1.5-8.67c.16-3.2.24-6.13-1.41-9-2.62-4.5-7.25-7.51-9.46-12.28-2.64-5.64-.65-11.79-1.41-17.71a14,14,0,0,0-3.87-7.77,61.87,61.87,0,0,1-5.49-6.17c-3.62-5.24-2.07-12-4.92-17.48-2.54-4.93-7.1-8.37-10.67-12.51a26.4,26.4,0,0,1-5.57-10.22l.31-.14c.44-.19.89-.4,1.37-.64a21.76,21.76,0,0,0,1.84,4.83,33.69,33.69,0,0,0,6,7.65c4.26,4.36,8.8,8.67,10,14.9.62,3,.63,6.19,1.54,9.17.85,2.82,2.77,5,4.78,7.06a27.64,27.64,0,0,1,5.06,6.25,17.84,17.84,0,0,1,1.64,8.5c0,3.08-.43,6.18-.09,9.26a14.51,14.51,0,0,0,3.31,7.6c3,3.81,7.26,7,8.59,11.93.89,3.23,0,6.55.32,9.83a19.22,19.22,0,0,0,3.08,8.13c1.52,2.5,3.28,4.86,4.8,7.37a34.9,34.9,0,0,1,3.7,8.5C159.67,234.91,159.84,241.35,159.62,247.72Z"
          fill="#fff"
          opacity="0.3"
        ></path>
        <path
          d="M167.79,251.76l-1.77.52a48.88,48.88,0,0,1-.3-9.43c.25-7.27-.07-14.73-2.81-21.57-2.24-5.6-6.58-10-9-15.49a18.72,18.72,0,0,1-1.49-8.68c.14-3.19.23-6.13-1.43-9-2.62-4.49-7.24-7.5-9.46-12.26-2.63-5.66-.64-11.8-1.41-17.72a14.08,14.08,0,0,0-3.85-7.77,60.85,60.85,0,0,1-5.5-6.18c-3.62-5.24-2.06-12-4.91-17.47-2.55-4.93-7.11-8.38-10.68-12.51a26.69,26.69,0,0,1-5.43-9.74c.56-.26,1.12-.51,1.68-.73a21.42,21.42,0,0,0,1.7,4.29,32.91,32.91,0,0,0,6,7.65c4.25,4.38,8.8,8.67,10,14.91.61,3,.62,6.18,1.53,9.16.87,2.82,2.78,5,4.79,7.06a27.64,27.64,0,0,1,5.06,6.26,17.81,17.81,0,0,1,1.63,8.49c0,3.08-.42,6.19-.08,9.27a14.7,14.7,0,0,0,3.31,7.6c3,3.81,7.25,7.05,8.59,11.93.9,3.24,0,6.54.32,9.82a19.22,19.22,0,0,0,3.08,8.13c1.52,2.51,3.29,4.86,4.81,7.38a34.13,34.13,0,0,1,3.68,8.5c1.75,6.17,1.92,12.63,1.72,19A45.49,45.49,0,0,0,167.79,251.76Z"
          fill="#fff"
          opacity="0.3"
        ></path>
        <path
          d="M209.23,238.51a4.15,4.15,0,0,1-2,1.18c-1.95.68-3.91,1.31-5.88,1.91l-1.77.52q-3.18.93-6.42,1.71l-1.38.33-.42.09a2.63,2.63,0,0,1-1.5-.05,3.06,3.06,0,0,1-1.42-1.72A31.25,31.25,0,0,1,185.66,231c-.27-5.81.76-12.54-3.38-16.62-2-2-5-2.93-6.61-5.26-2.23-3.22-.95-7.56-.78-11.48A19.6,19.6,0,0,0,168.12,182c-1.67-1.43-3.63-2.6-4.82-4.45a11.85,11.85,0,0,1-1.56-4.74c-1-7,.2-14.63-3.13-20.9-2.5-4.75-7.32-8-9.5-12.93-3.06-6.9-.37-15.76-4.81-21.85-1.79-2.46-4.52-4.09-6.42-6.47s-2.92-5.59-3.83-8.62a3.31,3.31,0,0,1,0-2.84,2.83,2.83,0,0,1,1.14-.84l.31-.14c.44-.19.89-.4,1.37-.64,2-1,4.2-2.24,6.4-3.26.57-.26,1.13-.51,1.69-.73a15.54,15.54,0,0,1,3.7-1.06c3.73-.49,5.67,2.83,7.78,5.66a50.7,50.7,0,0,1,9.45,22c.65,3.92.87,8,2.79,11.51,2.85,5.18,8.88,7.91,12,12.91,5.19,8.19,1.16,19.68,5.83,28.19,2.53,4.59,7.3,7.69,9.59,12.4,3.91,8.05-.35,18.64,4.58,26.1,1.2,1.79,2.83,3.24,4.14,5,4.14,5.38,4.6,12.64,4.89,19.42A4.27,4.27,0,0,1,209.23,238.51Z"
          fill="#24285b"
        ></path>
        <path
          d="M193.17,237.56c-.06,2-.15,4.16,0,6.27l-1.38.33-.42.09a63.5,63.5,0,0,1,0-7c.25-7.27-.07-14.73-2.81-21.58-2.25-5.59-6.58-10-9-15.48a18.62,18.62,0,0,1-1.5-8.68c.16-3.2.24-6.13-1.41-9-2.62-4.5-7.25-7.51-9.47-12.27-2.63-5.65-.64-11.79-1.4-17.72a14,14,0,0,0-3.87-7.76,63.12,63.12,0,0,1-5.49-6.18c-3.62-5.24-2.07-12-4.92-17.48-2.54-4.92-7.1-8.37-10.67-12.5a26.51,26.51,0,0,1-5.57-10.23l.31-.14c.44-.19.89-.4,1.37-.64a21.57,21.57,0,0,0,1.84,4.83,33.69,33.69,0,0,0,5.95,7.65c4.26,4.36,8.8,8.67,10.05,14.9.62,3,.62,6.19,1.54,9.17.85,2.82,2.77,5,4.78,7.06a27.87,27.87,0,0,1,5.06,6.25,17.86,17.86,0,0,1,1.64,8.5c0,3.08-.43,6.18-.09,9.27a14.51,14.51,0,0,0,3.31,7.59c3,3.81,7.26,7,8.59,11.93.89,3.24,0,6.55.32,9.83A19.08,19.08,0,0,0,183,202.7c1.52,2.5,3.28,4.86,4.8,7.37a34.8,34.8,0,0,1,3.7,8.51C193.22,224.75,193.39,231.19,193.17,237.56Z"
          fill="#fff"
          opacity="0.3"
        ></path>
        <path
          d="M201.34,241.6l-1.77.52a48.86,48.86,0,0,1-.3-9.43c.24-7.27-.07-14.73-2.81-21.56-2.24-5.61-6.58-10-9-15.5A19,19,0,0,1,186,187c.15-3.19.24-6.13-1.42-9-2.62-4.49-7.24-7.49-9.46-12.26-2.63-5.65-.64-11.8-1.41-17.72a14.08,14.08,0,0,0-3.85-7.77,61.83,61.83,0,0,1-5.5-6.17c-3.62-5.25-2.07-12-4.91-17.48-2.55-4.93-7.11-8.38-10.68-12.51a26.86,26.86,0,0,1-5.44-9.74c.57-.26,1.13-.51,1.69-.73a21.74,21.74,0,0,0,1.7,4.3,33.07,33.07,0,0,0,6,7.64c4.25,4.38,8.8,8.67,10,14.91.61,3,.62,6.18,1.53,9.16.87,2.83,2.78,5,4.79,7.06a27.92,27.92,0,0,1,5.06,6.26,17.81,17.81,0,0,1,1.63,8.49c0,3.09-.42,6.19-.08,9.27a14.7,14.7,0,0,0,3.31,7.6c3,3.81,7.25,7.05,8.59,11.93.89,3.24,0,6.54.32,9.82a19.12,19.12,0,0,0,3.08,8.13c1.52,2.51,3.29,4.86,4.8,7.38a34.16,34.16,0,0,1,3.69,8.5c1.75,6.17,1.92,12.63,1.72,19A45.47,45.47,0,0,0,201.34,241.6Z"
          fill="#fff"
          opacity="0.3"
        ></path>
        <path
          d="M285.12,159c-2.61,16.64-13.75,32.33-27.3,43.12-5.24,4.17-11.06,7.93-17.65,9.2-13.79,2.64-27.62-6.6-35-18.55s-9.78-26.21-12.53-40-6.27-28-15.25-38.8c-6.66-8-16.53-14.68-17.61-25-.47-4.51.92-9,3-13.08a37.9,37.9,0,0,1,15.09-15.73c10.48-6.06,23.65-7.64,35.91-5.49,19,3.33,28.78,26.51,39.28,40.25,11.45,15,27,28.13,31.43,46.48A44.55,44.55,0,0,1,285.12,159Z"
          fill="#fff"
        ></path>
        <path
          d="M204.08,134.94c3.92,9.51,10.25,18.45,19.37,23.21,6.28,3.27,13.49,4.59,19.88,1.18,4-2.12,7.86-4.61,10.75-8.08,7-8.38,5.66-20.92.07-29.61-6.09-9.46-22.76-23.57-34.82-23.13C200.09,99.22,198.54,121.48,204.08,134.94Z"
          fill="#ffd200"
        ></path>
        <path
          d="M285.12,159c-2.61,16.64-13.75,32.33-27.3,43.12-5.24,4.17-11.06,7.93-17.65,9.2-13.79,2.64-27.62-6.6-35-18.55s-9.78-26.21-12.53-40-6.27-28-15.25-38.8c-6.66-8-16.53-14.68-17.61-25-.47-4.51.92-9,3-13.08a37.9,37.9,0,0,1,15.09-15.73,36.88,36.88,0,0,0-.65,13.93c.77,5,2.72,9.69,4.39,14.45,5,14.3,7.69,29.31,12.33,43.72s11.6,28.66,23.28,38.32c7.31,6,17,10.13,26.27,8.18,12.71-2.65,21.18-15.5,33.72-18.9A25.38,25.38,0,0,1,285.12,159Z"
          opacity="0.05"
        ></path>
        <path
          d="M210.12,119.26a37.27,37.27,0,0,0,10.23,16.26,24.63,24.63,0,0,0,10.79,6.16,14.75,14.75,0,0,0,12-2.08c4.69-3.44,6.29-10.14,4.63-15.72s-6.08-10-11.19-12.83c-4.6-2.52-13.6-6.84-18.94-7.26C209.27,103.13,208.24,113.2,210.12,119.26Z"
          fill="#fff"
          opacity="0.3"
        ></path>
        <path
          d="M268,144c-3,0-3,4.65,0,4.65S271,144,268,144Z"
          opacity="0.05"
        ></path>
        <path
          d="M213.78,76.47c-3,0-3,4.66,0,4.66S216.78,76.47,213.78,76.47Z"
          opacity="0.05"
        ></path>
        <path
          d="M196.66,86.93c-3,0-3,4.66,0,4.66S199.66,86.93,196.66,86.93Z"
          opacity="0.05"
        ></path>
        <path
          d="M243.26,97.87c-3,0-3,4.66,0,4.66S246.26,97.87,243.26,97.87Z"
          opacity="0.05"
        ></path>
        <path
          d="M258.51,217a2.36,2.36,0,0,0-1.64-.2,2.18,2.18,0,0,0-1.71-.2,95.36,95.36,0,0,0-17.4,5.9,38.87,38.87,0,0,0-7.39,4.64c-2.31,1.85-4.71,4.12-5.93,6.86-1.45,3.26-.63,7,2.9,8.38,2.65,1.07,5.84.6,8.57.08a35.14,35.14,0,0,0,16-8.08,45.85,45.85,0,0,0,7.07-7.66,8.74,8.74,0,0,0,2.14-5.28A5.08,5.08,0,0,0,258.51,217Zm-9.62,13.85a31.06,31.06,0,0,1-12.65,6.78,16.58,16.58,0,0,1-6.48.63c-.49-.08-1.24-.26-1.41-.77-.3-.9.61-2.22,1.08-2.87,2.73-3.75,7-6.39,11.17-8.33a91.7,91.7,0,0,1,15.68-5.17C257.67,222.45,249.94,230,248.89,230.87Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M272.51,218.32a2.22,2.22,0,0,0-1.63-.2,2.14,2.14,0,0,0-1.71-.21,95.11,95.11,0,0,0-17.4,5.9,39.57,39.57,0,0,0-7.39,4.64c-2.31,1.85-4.72,4.13-5.93,6.86-1.45,3.27-.63,7,2.9,8.39,2.65,1.06,5.84.59,8.56.08a35.14,35.14,0,0,0,16-8.09A45.85,45.85,0,0,0,273,228a8.74,8.74,0,0,0,2.14-5.28A5,5,0,0,0,272.51,218.32Zm-9.61,13.84a30.94,30.94,0,0,1-12.65,6.78,16.58,16.58,0,0,1-6.48.64c-.49-.09-1.25-.26-1.42-.78-.29-.9.62-2.22,1.09-2.86,2.73-3.76,7-6.4,11.17-8.34a91.7,91.7,0,0,1,15.68-5.17C271.68,223.74,264,231.25,262.9,232.16Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M270,75.23a2.82,2.82,0,0,0-1.4-1.07l-8.06-3.36a2.32,2.32,0,0,0-1.8.23,2.32,2.32,0,0,0-.83,3.19,2.8,2.8,0,0,0,1.39,1.07l8.07,3.36a2.3,2.3,0,0,0,1.79-.23A2.4,2.4,0,0,0,270.24,77,2.35,2.35,0,0,0,270,75.23Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M221.7,216.63a2.33,2.33,0,0,0-1.07-1.4l-5.73-2.81a2.53,2.53,0,0,0-1.79-.23,2.37,2.37,0,0,0-1.39,1.07,2.34,2.34,0,0,0-.24,1.79,2.28,2.28,0,0,0,1.07,1.39l5.73,2.81a2.49,2.49,0,0,0,1.79.24,2.37,2.37,0,0,0,1.39-1.07A2.34,2.34,0,0,0,221.7,216.63Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M132.1,221.23a2.39,2.39,0,0,0-1.4-1.07,2.33,2.33,0,0,0-1.79.24l-4.28,2.85a2.56,2.56,0,0,0-1.07,1.39,2.41,2.41,0,0,0,.24,1.8,2.37,2.37,0,0,0,1.39,1.07,2.33,2.33,0,0,0,1.79-.24l4.28-2.85a2.34,2.34,0,0,0,.84-3.19Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M144.31,138.07l-1.92-6.65a2.33,2.33,0,1,0-4.49,1.24l1.92,6.65a2.33,2.33,0,1,0,4.49-1.24Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M179.42,128.11c-.47-2.06-.95-4.12-1.42-6.18a2.35,2.35,0,0,0-2.87-1.63,2.38,2.38,0,0,0-1.62,2.86c.47,2.06,1,4.12,1.42,6.19a2.33,2.33,0,0,0,4.49-1.24Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M275.4,204.28c-.07-.19-.15-.37-.23-.56a2.3,2.3,0,0,0-.84-.83l-5.7-4.29a2.38,2.38,0,0,0-1.79-.23,2.42,2.42,0,0,0-1.4,1.07,2.38,2.38,0,0,0-.23,1.79l.23.56a2.3,2.3,0,0,0,.84.83l5.7,4.29a2.36,2.36,0,0,0,1.8.23,2.4,2.4,0,0,0,1.39-1.07A2.29,2.29,0,0,0,275.4,204.28Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M147.46,64.35a2.38,2.38,0,0,0-3.29,0l-6.67,6.17a2.33,2.33,0,1,0,3.29,3.3l6.67-6.18A2.34,2.34,0,0,0,147.46,64.35Z"
          fill="#68e1fd"
        ></path>
        <path
          d="M131.26,84.87a22.26,22.26,0,0,1-6.65-5.82,2.31,2.31,0,0,0-1.65-.69,2.35,2.35,0,0,0-1.64.69,2.3,2.3,0,0,0-.68,1.64,2.73,2.73,0,0,0,.68,1.65,26.64,26.64,0,0,0,7.59,6.55,2.33,2.33,0,1,0,2.35-4Z"
          fill="#68e1fd"
        ></path>
      </svg>
      <svg
        id="wave"
        style={{ transform: "rotate(0deg)", transition: "0.3s" }}
        viewBox="0 0 1440 490"
        version="1.1"
        className="absolute left-0 bottom-0 right-0 hidden md:block"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 0px)", opacity: 1 }}
          fill="url(#sw-gradient-0)"
          d="M0,441L120,367.5C240,294,480,147,720,147C960,147,1200,294,1440,310.3C1680,327,1920,212,2160,179.7C2400,147,2640,196,2880,179.7C3120,163,3360,82,3600,98C3840,114,4080,229,4320,245C4560,261,4800,180,5040,155.2C5280,131,5520,163,5760,179.7C6000,196,6240,196,6480,204.2C6720,212,6960,229,7200,212.3C7440,196,7680,147,7920,171.5C8160,196,8400,294,8640,277.7C8880,261,9120,131,9360,122.5C9600,114,9840,229,10080,228.7C10320,229,10560,114,10800,65.3C11040,16,11280,33,11520,106.2C11760,180,12000,310,12240,367.5C12480,425,12720,408,12960,351.2C13200,294,13440,196,13680,196C13920,196,14160,294,14400,326.7C14640,359,14880,327,15120,334.8C15360,343,15600,392,15840,367.5C16080,343,16320,245,16560,187.8C16800,131,17040,114,17160,106.2L17280,98L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
        <defs>
          <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 50px)", opacity: 0.9 }}
          fill="url(#sw-gradient-1)"
          d="M0,343L120,343C240,343,480,343,720,326.7C960,310,1200,278,1440,236.8C1680,196,1920,147,2160,155.2C2400,163,2640,229,2880,253.2C3120,278,3360,261,3600,220.5C3840,180,4080,114,4320,114.3C4560,114,4800,180,5040,196C5280,212,5520,180,5760,163.3C6000,147,6240,147,6480,163.3C6720,180,6960,212,7200,228.7C7440,245,7680,245,7920,220.5C8160,196,8400,147,8640,122.5C8880,98,9120,98,9360,155.2C9600,212,9840,327,10080,318.5C10320,310,10560,180,10800,114.3C11040,49,11280,49,11520,98C11760,147,12000,245,12240,302.2C12480,359,12720,376,12960,351.2C13200,327,13440,261,13680,212.3C13920,163,14160,131,14400,171.5C14640,212,14880,327,15120,334.8C15360,343,15600,245,15840,212.3C16080,180,16320,212,16560,212.3C16800,212,17040,180,17160,163.3L17280,147L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
        <defs>
          <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 100px)", opacity: 0.8 }}
          fill="url(#sw-gradient-2)"
          d="M0,147L120,171.5C240,196,480,245,720,277.7C960,310,1200,327,1440,343C1680,359,1920,376,2160,392C2400,408,2640,425,2880,408.3C3120,392,3360,343,3600,302.2C3840,261,4080,229,4320,179.7C4560,131,4800,65,5040,65.3C5280,65,5520,131,5760,138.8C6000,147,6240,98,6480,122.5C6720,147,6960,245,7200,245C7440,245,7680,147,7920,122.5C8160,98,8400,147,8640,204.2C8880,261,9120,327,9360,334.8C9600,343,9840,294,10080,245C10320,196,10560,147,10800,106.2C11040,65,11280,33,11520,57.2C11760,82,12000,163,12240,196C12480,229,12720,212,12960,179.7C13200,147,13440,98,13680,89.8C13920,82,14160,114,14400,138.8C14640,163,14880,180,15120,187.8C15360,196,15600,196,15840,171.5C16080,147,16320,98,16560,138.8C16800,180,17040,310,17160,375.7L17280,441L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
        <defs>
          <linearGradient id="sw-gradient-3" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 150px)", opacity: 0.7 }}
          fill="url(#sw-gradient-3)"
          d="M0,147L120,179.7C240,212,480,278,720,310.3C960,343,1200,343,1440,294C1680,245,1920,147,2160,106.2C2400,65,2640,82,2880,130.7C3120,180,3360,261,3600,261.3C3840,261,4080,180,4320,138.8C4560,98,4800,98,5040,98C5280,98,5520,98,5760,98C6000,98,6240,98,6480,81.7C6720,65,6960,33,7200,57.2C7440,82,7680,163,7920,179.7C8160,196,8400,147,8640,114.3C8880,82,9120,65,9360,89.8C9600,114,9840,180,10080,228.7C10320,278,10560,310,10800,285.8C11040,261,11280,180,11520,130.7C11760,82,12000,65,12240,89.8C12480,114,12720,180,12960,212.3C13200,245,13440,245,13680,220.5C13920,196,14160,147,14400,179.7C14640,212,14880,327,15120,359.3C15360,392,15600,343,15840,343C16080,343,16320,392,16560,392C16800,392,17040,343,17160,318.5L17280,294L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
      </svg>
      <div className="h-5/12 z-20 mb-20 flex w-full bg-white lg:w-7/12">
        <div className="flex w-full flex-col space-y-4 self-center p-4 lg:w-1/2">
          <div className="mt-4 flex w-full items-center justify-center text-2xl font-medium text-sky-700">
            Welcome to Cook Like Me
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="flex flex-col space-y-2 px-4">
                <div className="flex items-center space-x-4">
                  <TextInput
                    required
                    {...register(`email`)}
                    label="Email"
                    className="w-full"
                    error={errors.email?.message}
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <PasswordInput
                    required
                    label="Password"
                    {...register(`password`)}
                    className="w-full"
                    error={errors.password?.message}
                  />
                </div>
              </div>
              <div className="p-4">
                <Button
                  variant={"gradient"}
                  className="w-full bg-primary"
                  type="submit"
                  loading={loading}
                >
                  Login
                </Button>
              </div>
              <div className="mb-4 cursor-pointer pl-4 text-sm ">
                <NavLink to={"/accounts/forget-password"}>
                  Did you forget your password ?
                </NavLink>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden h-full w-1/2 bg-white p-4 md:block">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 351.9 396.01"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa flex h-full w-full items-center text-primary"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="258.91"
                y1="124.54"
                x2="299.95"
                y2="123.91"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.01" />
                <stop offset="0.13" stopOpacity="0.69" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-2"
                x1="383.34"
                y1="84.62"
                x2="71.09"
                y2="410.77"
              />
              <linearGradient
                id="linear-gradient-3"
                x1="299.88"
                y1="206.77"
                x2="210.25"
                y2="300.4"
              />
              <linearGradient
                id="linear-gradient-4"
                x1="169.25"
                y1="193.08"
                x2="290.38"
                y2="268.95"
              />
              <linearGradient
                id="linear-gradient-5"
                x1="226.76"
                y1="234.44"
                x2="321.06"
                y2="234.44"
              />
              <linearGradient
                id="linear-gradient-6"
                x1="311.61"
                y1="323.82"
                x2="285.66"
                y2="317.16"
              />
              <linearGradient
                id="linear-gradient-7"
                x1="300.4"
                y1="335.06"
                x2="327.3"
                y2="335.06"
              />
              <linearGradient
                id="linear-gradient-8"
                x1="200.19"
                y1="81.05"
                x2="227.92"
                y2="104.12"
              />
              <linearGradient
                id="linear-gradient-9"
                x1="274.89"
                y1="85.38"
                x2="223.64"
                y2="105.68"
              />
              <linearGradient
                id="linear-gradient-10"
                x1="188.44"
                y1="144.76"
                x2="251.94"
                y2="142.56"
              />
              <linearGradient
                id="linear-gradient-11"
                x1="241.5"
                y1="248.75"
                x2="312.72"
                y2="218.8"
              />
            </defs>
            <path
              d="M406.19,147.48A70.63,70.63,0,0,1,395,206.08c-9,12.25-21.83,21.41-29.82,34.31-17.27,27.85-8,65.88-23.84,94.57-13.24,24-40.67,35.79-66.14,45.91C255.2,388.81,235,396.8,213.8,400s-44,1.17-62.37-9.86c-15.15-9.07-26.28-23.45-36.56-37.81C89.6,317,66.76,278.53,59,235.79s1.92-90.68,33.74-120.25c15.49-14.4,35.78-24.33,46.71-42.44,11.47-19,11.63-45.51,29.46-58.75,6.58-4.89,14.76-7.16,22.88-8.24A92.17,92.17,0,0,1,248.4,17c17.47,9.79,31.81,25.35,50.84,31.61,24.63,8.11,56.24,1.88,72.61,25.88C385,93.79,400.35,125,406.19,147.48Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
              opacity="0.18"
              style={{ isolation: "isolate" }}
            />
            <path
              d="M301.39,133.82a5.7,5.7,0,0,1-1.15,1.62,27.65,27.65,0,0,1-14.16,8,25.86,25.86,0,0,1-5.83.66c-1.15-7.42-2.32-14.85-3.22-22.3h0c-.71-5.82-1.24-11.63-1.49-17.47-.17-3.66-8.1-16.42-6.48-19.84,1.79-3.76,14.68,9.11,16.13,11.56,8.05,13.5,10.94,23.94,16.46,35.55A2.94,2.94,0,0,1,301.39,133.82Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M287.38,142.08a9.47,9.47,0,0,1-1.3,1.38,25.86,25.86,0,0,1-5.83.66c-1.15-7.42-2.32-14.85-3.22-22.3h0q.51-8.78,1.85-17.49c-.38,2.43,2.61,7.73,3.42,10.11l3.51,10.37C287.64,130.22,291.55,136.8,287.38,142.08Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient)"
            />
            <path
              d="M283.07,154.77c.8,4.88,1.82,9.82,4.23,14.14,1.24,2.22,3.09,4.4,5.6,4.79a8.65,8.65,0,0,0,4.1-.61,28.52,28.52,0,0,0,7.52-3.92,56.68,56.68,0,0,0,6.19-5.51,76.29,76.29,0,0,0,7.93-8.74c1.11-1.5,2.12-3.08,3.33-4.51,5-5.95,13-8.62,18.67-13.93,1.53-1.42,4.63-7.92,4.4-10-2.08-1.2-6.35,3.63-8.65,4.33s-4.45,1.84-6.76,2.49a16.54,16.54,0,0,1-8.47.11c-.94-.24-2.06-15.07-3-14.73a3.48,3.48,0,0,0-1.24.88c-1.94,2-3,19.07-4.61,21.29-2.21,3-5.27,5.28-8.3,7.46-.27.19-.61.39-.91.24a.91.91,0,0,1-.36-.62c-.79-2.93-1.68-5.84-2.65-8.71-.68-2-1.64-4.2-3.65-4.8-1.79-.53-3.65.48-5.25,1.45-2.06,1.26-4.14,2.5-6.19,3.77-1.14.7-2.52,1.38-3.06,2.67-.66,1.55-.1,3.69.09,5.28C282.33,150,282.68,152.39,283.07,154.77Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M301.32,290.78c0,3.06-.15,6.07-.42,9.06a100.21,100.21,0,0,1-200-8c0-.34,0-.67,0-1a100.22,100.22,0,1,1,200.43,0Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M301.32,290.78c0,3.06-.15,6.07-.42,9.06-23.06,15.69-57,25.59-94.85,25.59-44,0-82.66-13.37-105.15-33.64,0-.34,0-.67,0-1a100.22,100.22,0,1,1,200.43,0Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-2)"
            />
            <path
              d="M247.06,251.6c4,1.44,8.35,2.47,11.63,5.2,2.91,2.41,4.68,5.9,6.11,9.39a74.79,74.79,0,0,1,5,37c-.4,3.34-1,6.78-.13,10s3.84,6.24,7.18,5.84c4.63-9.2,4.73-19.94,4.74-30.24,0-7.52,0-15.2-2.52-22.29-2.06-5.84-5.7-11-9.46-15.91-13.71-18-30.3-34.79-51.42-42.88a4.17,4.17,0,0,0-2.84-.35c-4.35,1.59-3.64,9.33-1.95,12.44a115.45,115.45,0,0,0,8,12.39A54.91,54.91,0,0,0,247.06,251.6Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-3)"
            />
            <path
              d="M207.51,229a23.71,23.71,0,0,1,.52-4,3.66,3.66,0,0,1,2.67-2.71,12.81,12.81,0,0,0,1.64-.15c1.11-.34,1.58-1.63,1.9-2.74L217.79,207c.68-2.4,1.37-4.92.85-7.35-.82-3.8-4.31-6.37-7.71-8.23-2.47-1.36-5.25-2.57-8-2.06-4.26.79-6.73,5.19-8.32,9.22a43.46,43.46,0,0,0-3.37,13.59c-.28,5.41-.74,18.66,2.61,23.39C198.49,242.2,206.77,235,207.51,229Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-4)"
            />
            <path
              d="M261.45,213.35c9.07,3.07,18.74,4.36,27.48,8.27a2.13,2.13,0,0,1,1.3,1.11,2.19,2.19,0,0,1-.16,1.28,239.87,239.87,0,0,0-12.36,68,51,51,0,0,0,15.84.35,3.61,3.61,0,0,0,1.67-.53,3.69,3.69,0,0,0,1.06-1.58q12.69-28.49,22.78-58.05c1.19-3.49,2.37-7.14,1.89-10.8-.69-5.33-4.7-9.53-8.51-13.32l-29.69-29.62a8.05,8.05,0,0,0-2.72-2,7.74,7.74,0,0,0-3.68-.18c-11.71,1.45-27.7,1.9-49.22,1-2.79-.12,11,15.77,12.15,18.82.92,2.42,3.83,5.54,5.61,7.42C249.35,208.2,255.37,211.29,261.45,213.35Z"
              transform="translate(-56.44 -5.34)"
              fill="#525263"
            />
            <path
              d="M261.45,213.35c9.07,3.07,18.74,4.36,27.48,8.27a2.13,2.13,0,0,1,1.3,1.11,2.19,2.19,0,0,1-.16,1.28,239.87,239.87,0,0,0-12.36,68,51,51,0,0,0,15.84.35,3.61,3.61,0,0,0,1.67-.53,3.69,3.69,0,0,0,1.06-1.58q12.69-28.49,22.78-58.05c1.19-3.49,2.37-7.14,1.89-10.8-.69-5.33-4.7-9.53-8.51-13.32l-29.69-29.62a8.05,8.05,0,0,0-2.72-2,7.74,7.74,0,0,0-3.68-.18c-11.71,1.45-27.7,1.9-49.22,1-2.79-.12,11,15.77,12.15,18.82.92,2.42,3.83,5.54,5.61,7.42C249.35,208.2,255.37,211.29,261.45,213.35Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-5)"
            />
            <path
              d="M276.19,305.14a10.05,10.05,0,0,0-1.45,3.73,59.94,59.94,0,0,0-1.18,7.05c-.2,1.84-.27,3.84.78,5.35s2.78,2.06,4.4,2.69a99.47,99.47,0,0,1,11.86,5.83c2.58,1.42,5.27,2.89,8.21,3s6.19-1.5,6.86-4.36a8.35,8.35,0,0,0-.34-4.11,32.59,32.59,0,0,0-9.1-15,13.13,13.13,0,0,0-2.53-1.93,16.51,16.51,0,0,0-2.75-1.12,59.8,59.8,0,0,0-8.19-2.35C280.33,303.53,277.87,302.81,276.19,305.14Z"
              transform="translate(-56.44 -5.34)"
              fill="#2e2e3f"
            />
            <path
              d="M276.19,305.14a10.05,10.05,0,0,0-1.45,3.73,59.94,59.94,0,0,0-1.18,7.05c-.2,1.84-.27,3.84.78,5.35s2.78,2.06,4.4,2.69a99.47,99.47,0,0,1,11.86,5.83c2.58,1.42,5.27,2.89,8.21,3s6.19-1.5,6.86-4.36a8.35,8.35,0,0,0-.34-4.11,32.59,32.59,0,0,0-9.1-15,13.13,13.13,0,0,0-2.53-1.93,16.51,16.51,0,0,0-2.75-1.12,59.8,59.8,0,0,0-8.19-2.35C280.33,303.53,277.87,302.81,276.19,305.14Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-6)"
            />
            <path
              d="M279.16,292.76a66.11,66.11,0,0,0-1.18,12c0,1.77.12,3.73,1.39,5a5.79,5.79,0,0,0,4.26,1.22,16.5,16.5,0,0,0,5.37-1.11,2.35,2.35,0,0,0,1-.62,2.45,2.45,0,0,0,.41-.95,71.59,71.59,0,0,0,2.41-17.54,4.53,4.53,0,0,0-.55-2.68c-.95-1.33-2.92-1.27-4.52-.95a36.89,36.89,0,0,0-6,1.83C279.91,289.6,279.53,290.86,279.16,292.76Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M298.88,311.56a38.27,38.27,0,0,1,1.76,5.47c.31,1.45.49,3,1.42,4.16,1.18,1.45,3.23,1.82,5.1,1.85a10.32,10.32,0,0,0,4.51-.76,3.5,3.5,0,0,0,1.76-1.52,4.28,4.28,0,0,0,.28-2.16A34.5,34.5,0,0,0,310,304a56.15,56.15,0,0,0-4.44-6.72,4.17,4.17,0,0,0-2.43-2,4,4,0,0,0-2.91,1.11c-1.65,1.24-5.28,3.31-4.9,5.66C295.8,305.18,297.76,308.59,298.88,311.56Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M311,315.25a37.74,37.74,0,0,0-3.29,2.07c-1.65.91-3.59,1.09-5.4,1.61a2.42,2.42,0,0,0-1.26.67,2.36,2.36,0,0,0-.45,1.15,25.08,25.08,0,0,0,.33,8.19q1.38,8.74,3.22,17.41c.51,2.38,1.12,4.89,2.85,6.6s4.36,2.3,6.82,2.37a14.23,14.23,0,0,0,6.7-1.25,12.58,12.58,0,0,0,6.44-8.84,19.49,19.49,0,0,0-1.32-11.09,54.45,54.45,0,0,0-3.58-6.91q-2.7-4.64-5.38-9.28C315.32,315.59,314.08,313.94,311,315.25Z"
              transform="translate(-56.44 -5.34)"
              fill="#2e2e3f"
            />
            <path
              d="M311,315.25a37.74,37.74,0,0,0-3.29,2.07c-1.65.91-3.59,1.09-5.4,1.61a2.42,2.42,0,0,0-1.26.67,2.36,2.36,0,0,0-.45,1.15,25.08,25.08,0,0,0,.33,8.19q1.38,8.74,3.22,17.41c.51,2.38,1.12,4.89,2.85,6.6s4.36,2.3,6.82,2.37a14.23,14.23,0,0,0,6.7-1.25,12.58,12.58,0,0,0,6.44-8.84,19.49,19.49,0,0,0-1.32-11.09,54.45,54.45,0,0,0-3.58-6.91q-2.7-4.64-5.38-9.28C315.32,315.59,314.08,313.94,311,315.25Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-7)"
            />
            <path
              d="M189.84,132.21a27.32,27.32,0,0,0-1.74,6.86,164.27,164.27,0,0,0-1.82,18.39c-.5,11.76.26,23.54-.07,35.3-.34,12.23-1.78,24.91,2.62,36.32a14.07,14.07,0,0,0,3.38,5.5,6.4,6.4,0,0,0,6,1.64,6.66,6.66,0,0,0,4-4.35,19.83,19.83,0,0,0,.72-6.06l.16-11.18c0-.23,0-.5.23-.6a.58.58,0,0,1,.57.11,30.35,30.35,0,0,1,2.45,2.37c.89.72,2.31,1.07,3.1.24a2.59,2.59,0,0,0,.47-2.06c-.48-4.84-4.7-8.33-7.21-12.5-3.62-6-3.62-13.56-2.71-20.54,1.13-8.74,3.49-17.27,5.84-25.77l2-7.19c1.51-5.46,3-11.42.8-16.65a13.93,13.93,0,0,0-9.77-8C194.68,123.27,191.35,128.81,189.84,132.21Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M282.68,177.68c-1.29,3.47-8.57-.58-12.73,1.5-7.94,4-17-.11-25.85,0-9.42.17-15.92,9.45-25.27,10.63a5.1,5.1,0,0,1-2.06,0c-1.29-.38-2.05-1.67-2.6-2.88a25.15,25.15,0,0,1-1.72-5.83c-1.16-6.64,0-13.76,1.24-20.51q1.56-8.58,3.11-17.17,1.8-10,3.63-20.12a103.26,103.26,0,0,1,3-13.17c.24-.75.49-1.48.76-2.2A114.66,114.66,0,0,1,232.58,91c2-3.47,4-7,7.09-9.63,3.45-2.91,7.9-4.36,12.37-5.06a25,25,0,0,1,12.07.7c2.23.78,3.07,3.14,4.84,4.59a30.21,30.21,0,0,1,5.95,5.84,26.55,26.55,0,0,1,3.65,8.74c6.27,24.38-2.8,50.68,3.16,75.13C282.39,174.18,283.71,174.94,282.68,177.68Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <polygon
              points="217.72 86.7 218.5 91.81 215.5 90.14 215.5 103.68 212.08 88.64 215.39 83.82 217.72 86.7"
              fill="url(#linear-gradient-8)"
            />
            <path
              d="M248.76,91.71l1.89,1,3,2.61-8,21.46s11.62-17.88,12-27a50.63,50.63,0,0,0-2.07-16.6Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-9)"
            />
            <path
              d="M223.14,153.22a57.73,57.73,0,0,0,0,14.8c.41,3.09,1.07,6.17.86,9.27a2.71,2.71,0,0,1-.55,1.68,2.93,2.93,0,0,1-1.65.65q-4.69.74-9.39,1.51c-1.16-6.64,0-13.76,1.24-20.51q1.56-8.58,3.11-17.17,1.8-10,3.63-20.12a103.26,103.26,0,0,1,3-13.17c.24-.75.49-1.48.76-2.2,3.1-1.72,7.41-3,9.33-.22,2,3-.63,10-1.41,13C229.23,131.61,224.57,142,223.14,153.22Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-10)"
            />
            <path
              d="M295.07,66.52a16.57,16.57,0,0,1-.11,3.73,2.75,2.75,0,0,1-2.55,2.3c-3.12-.23-5.37,2.76-7.85,4.68a13.23,13.23,0,0,1-13.72,1.31,1.8,1.8,0,0,0-2.27,1,5.22,5.22,0,0,0-.2,2.75l1.15,10.94c.22,2.11.24,4.69-1.55,5.83s-3.91.15-5.39-1.14a18,18,0,0,1-4.87-8.17A38,38,0,0,1,265.82,53a23.39,23.39,0,0,1,5.77-4.68,24.89,24.89,0,0,1,7.2-2.43,19.57,19.57,0,0,1,6.86-.38A13.88,13.88,0,0,1,293.59,50c1.52,1.66,3,6.61,4.6,7.42C297,60.44,295.12,63.11,295.07,66.52Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M271.27,23.58a8.67,8.67,0,0,0-4.44.13c-2.82,1-4.4,3.95-5.42,6.76s-1.86,5.88-4.08,7.87c-1.91,1.72-4.62,2.41-6.34,4.31-2,2.21-2.19,5.48-2,8.45a11.87,11.87,0,0,0,1.81,6.3c1.06,1.44,2.69,2.44,3.52,4,.94,1.79,1.26,4.56,3.28,4.68a3.37,3.37,0,0,0,2.23-1,15.3,15.3,0,0,0,4.46-6.59c.49-1.42.81-3,1.89-4s3.28-1.06,3.77.36a4.52,4.52,0,0,1-.4,2.65c-.26.88-.15,2.07.71,2.37,1.62-2.5,2.53-5.44,4.45-7.71a11,11,0,0,1,17.37.82c1,1.43,1.68,3.13,3.08,4.15a8.78,8.78,0,0,0,4.37,1.28c3.44.34,7.08.47,10.18-1.06s5.39-5.24,4.2-8.49c-.94-2.55-3.62-4-6.22-4.82s-5.38-1.23-7.67-2.69c-3.11-2-4.77-5.56-6.58-8.79s-4.26-6.55-7.88-7.31a14.5,14.5,0,0,0-4.46,0,16.76,16.76,0,0,1-4.39.34C274.91,25.22,273.17,24,271.27,23.58Z"
              transform="translate(-56.44 -5.34)"
              fill="#2e2e3f"
            />
            <path
              d="M271.27,105.75a127.9,127.9,0,0,1,2,35.15c-.79,9.78-2.67,19.49-2.6,29.3a49.62,49.62,0,0,1-.25,8.27c-.44,2.72-18.68,1.68-21.34,1.65-4.48-.06,1.2,6-2.17,3.08-5.29-4.61-7.65-11.8-8.3-18.78-.93-9.95,1.11-19.91,3.33-29.66,1.54-6.75,3.18-13.52,5.94-19.87,3.42-7.85,8.83-17.84,10.57-26.23.25-1.23-4-5.7-2.17-5.64,1.19,0,.63,1.85.84,2.77a8.73,8.73,0,0,0,2.09,3.74,9.82,9.82,0,0,0,5.38,3.21c1.35.25,2.89.19,3.87,1.15,1.19,1.16,1,3.42,1.34,5C270.29,101.15,270.81,103.44,271.27,105.75Z"
              transform="translate(-56.44 -5.34)"
              fill="#f2f2f2"
            />
            <path
              d="M230.78,76c-4.84,2.22-9,5.66-12.93,9.26a108.36,108.36,0,0,0-9.2,9.36c-2.54,3-4.86,6.11-7.54,8.94-3.7,3.89-8.32,7.65-9.05,13a16.53,16.53,0,0,0,1.42,8.14,23.48,23.48,0,0,0,4.15,7.13,16.36,16.36,0,0,0,8.59,5,7.6,7.6,0,0,0,3.18.14,9.92,9.92,0,0,0,4-2.24,71.82,71.82,0,0,0,7.39-6.43c2.36-2.45,4.36-5.24,6.66-7.75,2-2.16,4.13-4.11,6.13-6.23,2.39-2.54,4.52-5.31,6.65-8.08a109.5,109.5,0,0,0,8.27-11.83,54.27,54.27,0,0,0,4.93-11.52c.77-2.62,2-5.94-.66-7.83C247.12,71.08,236.41,73.37,230.78,76Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M230.78,76c-4.84,2.22-9,5.66-12.93,9.26a108.36,108.36,0,0,0-9.2,9.36c-2.54,3-4.86,6.11-7.54,8.94-3.7,3.89-8.32,7.65-9.05,13a16.53,16.53,0,0,0,1.42,8.14,23.48,23.48,0,0,0,4.15,7.13,16.36,16.36,0,0,0,8.59,5,7.6,7.6,0,0,0,3.18.14,9.92,9.92,0,0,0,4-2.24,71.82,71.82,0,0,0,7.39-6.43c2.36-2.45,4.36-5.24,6.66-7.75,2-2.16,4.13-4.11,6.13-6.23,2.39-2.54,4.52-5.31,6.65-8.08a109.5,109.5,0,0,0,8.27-11.83,54.27,54.27,0,0,0,4.93-11.52c.77-2.62,2-5.94-.66-7.83C247.12,71.08,236.41,73.37,230.78,76Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M314.61,121.66a4.92,4.92,0,0,0-1.61,6.66,25,25,0,0,0,6.62,7.5c1.6,1.72,5.2,2,8.4.12s18.47-10.19,26.1-15.62a5,5,0,0,0,1.13-7.17l-6.39-8a4.92,4.92,0,0,0-6.48-1.08Z"
              transform="translate(-56.44 -5.34)"
              fill="#3d3d54"
            />
            <path
              d="M214.5,190l-.23-.23"
              transform="translate(-56.44 -5.34)"
              fill="none"
            />
            <path
              d="M213.12,207.85a30.09,30.09,0,0,0,5.87,11.3c3,3.5,7.08,6,11,8.38l16.51,10.05a57.31,57.31,0,0,1,6.93,4.69c5.41,4.46,9,10.68,12.49,16.76L290,301c1.22,2.15,2.93,4.58,5.4,4.5A6.91,6.91,0,0,0,299,303.9c3.09-2.17,6.41-4.64,7.33-8.31.64-2.56,0-5.25-.77-7.79-3.7-12.51-9.5-24.29-15.32-36Q276.37,224,262.09,196.31c-2.53-4.9-5.6-12.53-9.78-16.13,0,0-38.84-2.36-39.82,1.49A48.73,48.73,0,0,0,213.12,207.85Z"
              transform="translate(-56.44 -5.34)"
              fill="#525263"
            />
            <path
              d="M213.12,207.85a30.09,30.09,0,0,0,5.87,11.3c3,3.5,7.08,6,11,8.38l16.51,10.05a57.31,57.31,0,0,1,6.93,4.69c5.41,4.46,9,10.68,12.49,16.76L290,301c1.22,2.15,2.93,4.58,5.4,4.5A6.91,6.91,0,0,0,299,303.9c3.09-2.17,6.41-4.64,7.33-8.31.64-2.56,0-5.25-.77-7.79-3.7-12.51-9.5-24.29-15.32-36Q276.37,224,262.09,196.31c-2.53-4.9-5.6-12.53-9.78-16.13,0,0-38.84-2.36-39.82,1.49A48.73,48.73,0,0,0,213.12,207.85Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-11)"
            />
            <polygon
              points="200.36 65.18 194.87 67.35 191.54 85.98 202.61 85.48 200.36 65.18"
              fill="#68e1fd"
            />
            <path
              d="M268.42,90.37c0,.84,0,1.68,0,2.52a9.82,9.82,0,0,0,.06,1.09,10.08,10.08,0,0,0,.24,1.09l1.38,5.44.57-8.84c1.63.79,3.3,1.51,5,2.14a12.59,12.59,0,0,0-1-3.84c-1-2.56-1.92-5.13-3-7.64a6.28,6.28,0,0,0-1.08-1.84,2.31,2.31,0,0,0-1.9-.8c-1,.42-.37,4.17-.36,5Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M349.32,117.28,329,130.13a1.78,1.78,0,0,1-2.19-.4l-6.46-7.28a1.2,1.2,0,0,1,.16-1.88l20.36-12.84a1.78,1.78,0,0,1,2.19.4l6.46,7.27A1.2,1.2,0,0,1,349.32,117.28Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Login
