/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Permission } from "../../../models/permission.model";
import { Role } from "../../../models/role.model";
import { Employee } from "../../../models/employee.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { EMPLOYEE_ENDPOINT } from "../employee.endpoint";

let employeeCollection: CollectionQuery;
let archivedEmployeeCollection: CollectionQuery;
let rolePermissionCollection: any;
const employeeQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployee: builder.query<Employee, string>({
      query: (id: string) => ({
        url: `${EMPLOYEE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedEmployee: builder.query<Employee, string>({
      query: (id: string) => ({
        url: `${EMPLOYEE_ENDPOINT.archivedEmployee}/${id}`,
        method: "get",
      }),
    }),

    getArchivedEmployees: builder.query<Collection<Employee>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${EMPLOYEE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedEmployeeCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getEmployees: builder.query<Collection<Employee>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${EMPLOYEE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            employeeCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getRoles: builder.query<Collection<Role>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${EMPLOYEE_ENDPOINT.roles}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          //
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getEmployeeRoles: builder.query<Collection<Role>, string>({
      query: (id: string) => ({
        url: `${EMPLOYEE_ENDPOINT.user_role}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          //
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createEmployee: builder.mutation<Employee, Employee>({
      query: (newData: any) => ({
        url: `${EMPLOYEE_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permissions:'manage-employees'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployees",
                employeeCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateEmployee: builder.mutation<Employee, Employee>({
      query: (newData: any) => ({
        url: `${EMPLOYEE_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission:'manage-employees'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployees",
                employeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((employee) => {
                      if (employee.id === data.id) return data;
                      else {
                        return employee;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateEmployeeProfile: builder.mutation<Employee, any>({
      query: (newData: any) => ({
        url: `${EMPLOYEE_ENDPOINT.updateProfile}` + newData.id,
        method: "post",
        data: newData.data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployees",
                employeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((employee) => {
                      if (employee.id === data.id) return data;
                      else {
                        return employee;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateEmployee: builder.mutation<Employee, string>({
      query: (id: string) => ({
        url: `${EMPLOYEE_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permission:'manage-employees'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployees",
                employeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((employee) => {
                      if (employee.id === data.id) return data;
                      else {
                        return employee;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              employeeQuery.util.updateQueryData("getEmployee", param, (draft) => {
                draft.enabled = data.enabled;
              })
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    archiveEmployee: builder.mutation<Employee, { id: string; reason: string }>({
      query: (data) => ({
        url: `${EMPLOYEE_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission:'manage-employees'
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployees",
                employeeCollection,
                (draft) => {
                  draft.data = draft?.data?.filter(employee => employee.id !== arg.id);
                  draft.count--;
                }
              )
            );
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployee",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              employeeQuery.util.updateQueryData(
                "getArchivedEmployee",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
           
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    deleteEmployee: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${EMPLOYEE_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission:'manage-employees'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              employeeQuery.util.updateQueryData(
                "getArchivedEmployees",
                archivedEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((employee) => employee.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    restoreEmployee: builder.mutation<Employee, string>({
      query: (id: string) => ({
        url: `${EMPLOYEE_ENDPOINT.restore}/${id}`,
        method: "post",
        permission:'manage-employees'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployees",
                employeeCollection,
                (draft) => {
                  draft.data = draft?.data?.map((provider) => {
                    if (provider.id === id) {
                      return data;
                    } else {
                      return provider;
                    }
                  });
                }
              )
            );
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployee",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              employeeQuery.util.updateQueryData(
                "getArchivedEmployee",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getRolePermissions: builder.query<Collection<Permission>, any>({
      query: (data: { roleId: string; collection: CollectionQuery }) => ({
        url: `${EMPLOYEE_ENDPOINT.role_permissions}/${data.roleId}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          rolePermissionCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getEmployeeRolePermissions: builder.query<Permission[], any>({
      query: (data: { roleId: string; accountId: string }) => ({
        url: `${EMPLOYEE_ENDPOINT.user_role_permissions}/${data.accountId}/${data.roleId}`,
        method: "GET",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          //
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    addRoleToAccount: builder.mutation<Role[], any>({
      query: (data: any) => ({
        url: `${EMPLOYEE_ENDPOINT.add_account_role}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployeeRoles",
                param.accountId,
                (draft) => {
                  if (data) {
                    draft.data = data;
                    draft.count += data.length;
                  }
                }
              )
            );
            notification("success", "Successfully assigned");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    addAccountRolePermissions: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${EMPLOYEE_ENDPOINT.add_account_role_permission}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployeeRolePermissions",
               {roleId:param.roleId,accountId:param.accountId},
                (draft) => {
                  if (data) {
                    draft = draft.concat(data);
                  }
                }
              )
            );
            notification("success", "Permission Successfully assigned");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    removeAccountRole: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${EMPLOYEE_ENDPOINT.remove_account_role}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              employeeQuery.util.updateQueryData(
                "getEmployeeRoles",
                param.accountId,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((role) => role.id !== param.roleId);
                    draft.count-=1;
                  }
                }
              )
            );
            notification("success", "Successfully removed");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetEmployeeQuery,
  useLazyGetArchivedEmployeeQuery,
  useLazyGetEmployeesQuery,
  useLazyGetArchivedEmployeesQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useUpdateEmployeeProfileMutation,
  useArchiveEmployeeMutation,
  useActivateEmployeeMutation,
  useRestoreEmployeeMutation,
  useDeleteEmployeeMutation,
  useLazyGetRolesQuery,
  useLazyGetEmployeeRolesQuery,
  useLazyGetRolePermissionsQuery,
  useAddRoleToAccountMutation,
  useLazyGetEmployeeRolePermissionsQuery,
  useAddAccountRolePermissionsMutation,

  useRemoveAccountRoleMutation
} = employeeQuery;
