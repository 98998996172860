import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../../models/role.model';

export interface Auth {
  loading: boolean;
  role: Role|null;
}

const validateJson=()=>{
  if(localStorage?.currentRole){
    try{
     return JSON.parse(localStorage.currentRole)
    }catch(e){
      localStorage.removeItem("currentRole")
      return null
    }

  }

}


const initialState: Auth = {
  loading: false,
  role:validateJson(),
};

export const authSlice = createSlice({
  name: 'authLoading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>): void => {
      state.loading = action.payload;
    },
    setRole: (state, action: PayloadAction<Role>): void => {
      state.role = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading,setRole } = authSlice.actions;
// export const selectLocale = (state:RootState) => state.locale;
export default authSlice.reducer;
